import {useAppDispatch} from './';
import {
  setUser as setUserAction,
  setUsers as setUsersAction,
  setTickets as setTicketsAction,
  setEvents as setEventsAction,
  setFinalEvents as setFinalEventsAction,
  setHiddenColumns as setHiddenColumnsAction,
  setStartDate as setStartDateAction,
  setEndDate as setEndDateAction,
  setHaulerFilter as setHaulerFilterAction,
  setTrailerIdFilter as setTrailerIdFilterAction,
  setSiteFilter as setSiteFilterAction,
  setTimeZone as setTimeZoneAction,
  setCombineRows as setCombineRowsAction,
  setShowAccuracyReport as setShowAccuracyReportAction,
  addRawTicket as addRawTicketActiion,
  setPageName as setPageNameAction,
} from '../redux/slice';

export const useActions = () => {
  const dispatch = useAppDispatch();

  const setUser = payload => {
    dispatch(setUserAction(payload));
  };

  const setUsers = payload => {
    dispatch(setUsersAction(payload));
  };

  const setTickets = payload => {
    dispatch(setTicketsAction(payload));
  };

  const setEvents = payload => {
    dispatch(setEventsAction(payload));
  };

  const setFinalEvents = payload => {
    dispatch(setFinalEventsAction(payload));
  };

  const setHiddenColumns = payload => {
    dispatch(setHiddenColumnsAction(payload));
  };

  const setStartDate = payload => {
    dispatch(setStartDateAction(payload));
  };

  const setEndDate = payload => {
    dispatch(setEndDateAction(payload));
  };

  const setHaulerFilter = payload => {
    dispatch(setHaulerFilterAction(payload));
  };

  const setTrailerIdFilter = payload => {
    dispatch(setTrailerIdFilterAction(payload));
  };

  const setSiteFilter = payload => {
    dispatch(setSiteFilterAction(payload));
  };

  const setTimeZone = payload => {
    dispatch(setTimeZoneAction(payload));
  };

  const setCombineRows = payload => {
    dispatch(setCombineRowsAction(payload));
  };

  const setShowAccuracyReport = payload => {
    dispatch(setShowAccuracyReportAction(payload));
  };

  const addRawTicket = payload => {
    dispatch(addRawTicketActiion(payload));
  };

  const setPageName = payload => {
    dispatch(setPageNameAction(payload));
  };


  return {
    setUser,
    setUsers,
    setTickets,
    setEvents,
    setFinalEvents,
    setHiddenColumns,
    setStartDate,
    setEndDate,
    setHaulerFilter,
    setTrailerIdFilter,
    setSiteFilter,
    setTimeZone,
    setCombineRows,
    setShowAccuracyReport,
    addRawTicket,
    setPageName
  };
};
