import {useEffect, useState} from 'react';
import {useAuth} from './';
import {fetchSites} from '../api';
import {Model, Site} from '../models';

export const useSitesData = () => {
  const {authToken} = useAuth();
  const [sites, setSites] = useState<Site[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!authToken) return;

    setLoading(true);
    fetchSites({authToken})
      .then(response => {
        const sites = response.data.map(item =>
          Model.createSiteFromApiObject(item),
        );
        setSites(sites);
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return {sites, loading};
};
