import {css} from '@emotion/css';
import { useEffect } from 'react';
import {Table, TableBody, TableRow, TableCell} from 'semantic-ui-react';
import {Loader, Page} from '../components';
import {useActions, useLoggedInUserData} from '../hooks';

const styles = {
  grid: css`
    display: grid;
    padding: 20px;
    grid-template-columns: repeat(6, minmax(0, 1fr));
    gap: 20px;
    @media (max-width: 719px) {
      grid-template-areas: 't t t t t t';
    }
    @media (min-width: 720px) {
      grid-template-areas: '. t t t t .';
    }
  `,
  gridAreaTable: css({
    gridArea: 't',
  }),
};

export const ProfilePage = () => {
  const {user, loading} = useLoggedInUserData();
  const {setPageName} = useActions();

  useEffect(() => {
    setPageName('Profile');
  }, [setPageName]);

  const rows = [
    {
      label: 'Name',
      value: user && user.name,
    },
    {
      label: 'Email',
      value: user && user.email,
    },
    {
      label: 'User Type',
      value: user && user.userType,
    },
    ...(user && user.isSuperAdmin
      ? [
          {
            label: 'Super Admin',
            value: user && String(user.isSuperAdmin),
          },
        ]
      : []),
  ];

  return (
    <Page>
      <div className={styles.grid}>
        <div className={styles.gridAreaTable}>
          {loading ? (
            <Loader />
          ) : (
            // @ts-ignore
            <Table celled>
              <TableBody>
                {rows.map(row => (
                  <TableRow key={row.label}>
                    <TableCell style={{fontWeight: 'bold'}}>
                      {row.label}
                    </TableCell>
                    <TableCell>{row.value}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}
        </div>
      </div>
    </Page>
  );
};
