import {
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHeaderCell,
  TableRow,
} from 'semantic-ui-react';
import {Trailer} from '../models';

interface TrailersTableProps {
  trailers: Trailer[];
  columns: any[];
}

export const TrailersTable = ({trailers, columns}: TrailersTableProps) => {
  return (
    // @ts-ignore
    <Table celled structured unstackable>
      <TableHeader>
        <TableRow>
          {columns.map(({key, title}) => (
            <TableHeaderCell
              // sorted={sortColumn === key ? sortDirection : null}
              // onClick={() => onClickHeaderCell(key)}
              key={key}>
              {title}
            </TableHeaderCell>
          ))}
        </TableRow>
      </TableHeader>
      <TableBody>
        {trailers.map(trailer => (
          <TableRow key={trailer.id}>
            {columns.map(({key: columnKey}, index) => {
              return (
                <TableCell key={`${trailer.id}-${columnKey}-${index}`}>
                  {trailer[columnKey]}
                </TableCell>
              );
            })}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};
