interface LogoInlineSvgProps {
  color?: string;
  height?: string;
  onClick?: () => void;
}

export const LogoInlineSvg = ({
  color = '#ffffff',
  height = '35px',
  onClick,
}: LogoInlineSvgProps) => (
  <svg
    fill={'#ffffff'}
    viewBox="181.597 251.01 136.807 40.505"
    xmlns="http://www.w3.org/2000/svg"
    onClick={onClick}
    style={{height, cursor: onClick ? 'pointer' : undefined}}>
    <path
      style={{
        fill: color,
        fillOpacity: 1,
        fillRule: 'evenodd',
        stroke: 'none',
      }}
      d="M772.059 1361.69c38.109-50.47 26.015-88.18 6.136-122.88-9.215.3-21.336.58-29.425 3.96l-10.778 4.48 1.094-11.62c.324-3.43 2.527-6.88 5.402-9.9-61.293-22.91-51.383-96.93.289-125.69-33.765 13.2-55.886 45.57-53.386 70.68 7.355 73.8 107.718 94.15 80.668 190.97"
      transform="matrix(.13333 0 0 -.13333 89.437 432.569)"
    />
    <path
      style={{
        fill: color,
        fillOpacity: 1,
        fillRule: 'evenodd',
        stroke: 'none',
      }}
      d="M852.32 1265.25c82.305-143.82-80.496-250.01-158.742-127.34 45.735-164.203 291.516-52.66 158.742 127.34"
      transform="matrix(.13333 0 0 -.13333 89.437 432.569)"
    />
    <path
      style={{
        fill: color,
        fillOpacity: 1,
        fillRule: 'evenodd',
        stroke: 'none',
      }}
      d="M850.18 1166.4c2.972 16.69 3.324 32.5-21.528 39.91-13.718 6.43-21.968-3.09-17.062 11.33 3.398-4.88 11.055-5.5 16-9.98-5.985 34.44-56.192 18.08-81.52 28.63.414-4.38 8.731-10.43 12.039-12.22-18.218-7.48-42.164-19.74-46.644-38.08 41.453 26.61 128.695 16.69 138.715-19.59"
      transform="matrix(.13333 0 0 -.13333 89.437 432.569)"
    />
    <path
      style={{
        fill: color,
        fillOpacity: 1,
        fillRule: 'evenodd',
        stroke: 'none',
      }}
      d="M840.766 1180.07c-74.235 20.05-54.965-58.87 4.968-61.5a84.927 84.927 0 0 0-10.32-7.45c-7.91-4.88-15.621-8.64-23.199-11.24-62.836 38.9-38.719 123.01 28.551 80.19"
      transform="matrix(.13333 0 0 -.13333 89.437 432.569)"
    />
    <path
      style={{
        fill: color,
        fillOpacity: 1,
        fillRule: 'evenodd',
        stroke: 'none',
      }}
      d="M711.93 1146.43c13.605 26.52 40.562 44.95 90.269 47.57-34.656 7.87-68.906 1.07-91.551-11.95-2.375-12.76-2.16-24.72 1.282-35.62"
      transform="matrix(.13333 0 0 -.13333 89.437 432.569)"
    />
    <path
      style={{
        fill: color,
        fillOpacity: 1,
        fillRule: 'evenodd',
        stroke: 'none',
      }}
      d="M730.777 1118.79c2.418 22.86 17.313 52.14 48.969 68.41-36.984-6.26-57.433-25.25-65.207-47.53 3.531-7.6 8.859-14.59 16.238-20.88"
      transform="matrix(.13333 0 0 -.13333 89.437 432.569)"
    />
    <path
      style={{
        fill: color,
        fillOpacity: 1,
        fillRule: 'evenodd',
        stroke: 'none',
      }}
      d="M762.898 1099.77c-5.296 21.84-.656 59.57 11.704 80.91-19.668-10.59-37.61-42.51-38.059-66.43 8.875-6.55 17.621-11.37 26.355-14.48"
      transform="matrix(.13333 0 0 -.13333 89.437 432.569)"
    />
    <path
      style={{
        fill: color,
        fillOpacity: 1,
        fillRule: 'evenodd',
        stroke: 'none',
      }}
      d="M799.465 1096.57c-15.102 12.21-30.488 39.18-28.988 62.36-6.856-20.26-5.184-48.79 3.738-62.25 8.351-1.57 16.734-1.6 25.25-.11M963.488 1217.37v18.58h111.182v-18.53h-85.916v-.05h-25.266M963.488 1127.29v65.08h75.392v-17.69h-50.126v-26.96h86.966v-20.43H963.488"
      transform="matrix(.13333 0 0 -.13333 89.437 432.569)"
    />
    <path
      style={{
        fill: color,
        fillOpacity: 1,
        fillRule: 'nonzero',
        stroke: 'none',
      }}
      d="M1150.23 1170.67h47.66l-23.48 44.22zm-48.64-43.38 61.52 108.66h24.39l62.34-108.66h-28.39l-13.06 23.8h-68.93l-12.67-23.8h-25.2M1383.02 1216.58h-78.96v-68.86h78.96v24.22h-41.27v18.52h65.91v-37.48c0-9.93-1.86-16.7-5.58-20.28-3.68-3.62-10.84-5.41-21.4-5.41h-74.97c-10.57 0-17.69 1.79-21.37 5.41-3.69 3.58-5.55 10.35-5.55 20.28v57.28c0 9.86 1.86 16.6 5.55 20.25 3.68 3.62 10.8 5.44 21.37 5.44h74.97c10.42 0 17.54-1.79 21.33-5.33 3.76-3.58 5.65-10.01 5.65-19.31v-2.49l-24.64-4.46v12.22M1461.15 1127.29v108.66h25.27v-88.23h81.49v-20.43h-106.76"
      transform="matrix(.13333 0 0 -.13333 89.437 432.569)"
    />
    <path
      style={{
        fill: color,
        fillOpacity: 1,
        fillRule: 'evenodd',
        stroke: 'none',
      }}
      d="M1605.01 1217.37v18.58h111.19v-18.53h-85.92v-.05h-25.27M1605.01 1127.29v65.08h75.39v-17.69h-50.12v-26.96h86.97v-20.43h-112.24"
      transform="matrix(.13333 0 0 -.13333 89.437 432.569)"
    />
    <path
      style={{
        fill: color,
        fillOpacity: 1,
        fillRule: 'nonzero',
        stroke: 'none',
      }}
      d="M1122.41 1058.11v34.48h34.88v-6.02h-26.86v-9.09h14.5v-6.28h-14.5v-13.09zm44.82 0v34.48h8.02v-34.48zm25.65 0v34.48h35.28v-5.89h-27.27v-7.68h15.91v-5.88h-15.91v-8.55h27.6v-6.48zm50.43 0v34.48h8.02v-28h25.85v-6.48zm53.66 6.48h10.38c5.53 0 9.45.86 11.75 2.58 2.31 1.72 3.47 4.56 3.47 8.52 0 3.53-.96 6.2-2.88 8.02-1.92 1.81-4.74 2.73-8.48 2.73h-14.24zm-8.02-6.48v34.48h22.19c6.33 0 11.2-1.49 14.6-4.44 3.4-2.96 5.11-7.16 5.11-12.59 0-3.22-.67-6.11-1.99-8.7-1.33-2.57-3.2-4.6-5.62-6.08-1.5-.93-3.36-1.62-5.59-2.04-2.24-.42-5.08-.63-8.57-.63zm98.51 28.33v-28.33h-8.01v28.33h-15.84v6.15h39.76v-6.15zm25.73-28.33v34.48h35.28v-5.89h-27.26v-7.68h15.9v-5.88h-15.9v-8.55h27.59v-6.48zm79.36 28.33h-21.64v-21.85h21.64v7.42l8.02-1.67v-4.08c0-3.15-.59-5.3-1.78-6.44-1.18-1.14-3.43-1.71-6.76-1.71h-20.6c-3.35 0-5.61.57-6.78 1.71-1.17 1.14-1.76 3.29-1.76 6.44v18.19c0 3.11.59 5.26 1.76 6.41 1.17 1.14 3.43 1.73 6.78 1.73h20.6c3.33 0 5.58-.59 6.76-1.73 1.19-1.15 1.78-3.3 1.78-6.41v-3.28l-8.02-1.28zm24.58-28.33v34.48h8.02v-13.37h25.05v13.37h8.09v-34.48h-8.09v14.83h-25.05v-14.83h-8.02"
      transform="matrix(.13333 0 0 -.13333 89.437 432.569)"
    />
  </svg>
);
