import {secondsToMinutes} from 'date-fns';
import {formatInTimeZone} from 'date-fns-tz';
import {round} from 'mathjs';
import {TimeZone} from '../enums';
import {convertTimeZoneToFormatString} from '../utils';

const formatDate = (dateString: string, timeZone: TimeZone) => {
  const date = new Date(dateString + 'Z');
  const timeZoneFormatString = convertTimeZoneToFormatString(timeZone);
  return formatInTimeZone(date, timeZoneFormatString, 'M-d-yyyy HH:mm:ss');
};

const roundAndFormatVolume = volume => {
  return round(volume, 2).toFixed(2);
};

// converts number of seconds to hours:minutes:seconds format
// const formatDuration = value => {
//   const hours = secondsToHours(value);
//   const minutes = secondsToMinutes(value) - hours * 60;
//   const seconds = value - hours * 3600 - minutes * 60;

//   const minutesString = String(minutes).padStart(2, '0');
//   const secondsString = String(seconds).padStart(2, '0');

//   return `${hours}:${minutesString}:${secondsString}`;
// };

export const eventTableColumns = [
  {
    key: 'ticketId',
    title: 'Ticket ID',
    span: true,
    type: 'number',
  },
  {
    key: 'hauler',
    title: 'Hauler',
    span: true,
    type: 'string',
  },
  {
    key: 'trailerId',
    title: 'Trailer Id',
    span: true,
    type: 'number',
  },
  {
    key: 'deviceSerialNumber',
    title: 'Serial Number',
    span: true,
    type: 'string',
  },
  {
    key: 'type',
    title: 'Type',
    type: 'string',
  },
  {
    key: 'site',
    title: 'Site',
    type: 'string',
  },
  {
    key: 'operator',
    title: 'Operator',
    type: 'string',
  },
  {
    key: 'latitude',
    title: 'Latitude',
    type: 'number',
  },
  {
    key: 'longitude',
    title: 'Longitude',
    type: 'number',
  },
  {
    key: 'startTime',
    title: 'Start Time',
    display: (value, {timeZone}) => formatDate(value, timeZone),
    type: 'date',
    useDisplayValueInExport: true,
  },
  {
    key: 'endTime',
    title: 'End Time',
    display: (value, {timeZone}) => formatDate(value, timeZone),
    type: 'date',
    useDisplayValueInExport: true,
  },
  {
    key: 'siteArrivalTime',
    title: 'Site Arrival Time',
    display: (value, {timeZone}) => formatDate(value, timeZone),
    type: 'date',
    useDisplayValueInExport: true,
  },
  {
    key: 'siteDepartureTime',
    title: 'Site Departure Time',
    display: (value, {timeZone}) => formatDate(value, timeZone),
    type: 'date',
    useDisplayValueInExport: true,
  },
  {
    key: 'duration',
    title: 'Duration',
    display: value => secondsToMinutes(value),
    type: 'number',
    useDisplayValueInExport: true,
  },
  {
    key: 'startVolume',
    title: 'Start Volume',
    display: value => roundAndFormatVolume(value),
    type: 'number',
  },
  {
    key: 'endVolume',
    title: 'End Volume',
    display: value => roundAndFormatVolume(value),
    type: 'number',
  },
  {
    key: 'netVolume',
    title: 'Net Volume',
    display: value => roundAndFormatVolume(value),
    type: 'number',
  },
];
