import { css } from '@emotion/css';
import {round} from 'mathjs';
import {uniq, filter} from 'ramda';
import {useEffect, useState, CSSProperties} from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHeaderCell,
  TableRow,
} from 'semantic-ui-react';
import { ACCURACY_FILTER_DURATION_OVER_60, ACCURACY_FILTER_DURATION_UNDER_5, ACCURACY_FILTER_END_VOLUME_GREATER_THAN_2, ACCURACY_FILTER_NEGATIVE_VOLUME, ACCURACY_FILTER_NULL_DURATION, ACCURACY_FILTER_START_VOLUME_GREATER_THAN_2 } from '../helpers/constants';
import { durationNullFilter, durationOver60TicketIdsFilter, durationUnder5TicketIdsFilter, endingVolumeGreaterThan2Filter, negativeVolumeTicketIdsFilter, startingVolumeGreaterThan2Filter } from '../helpers/filters';
import {Event} from '../models';

interface AccuracyReportTableProps {
  events: Event[];
  toggleFilterAccuracy: any;
  accuracyFilterExists: any;
  loadingFields: any;
  style?: CSSProperties;
}

export const AccuracyReportTable = ({
  events,
  toggleFilterAccuracy,
  accuracyFilterExists,
  loadingFields,
  style,
}: AccuracyReportTableProps) => {
  const [totalTicketCount, setTotalTicketCount] = useState(null);
  const [negativeVolumeCount, setNegativeVolumeCount] = useState(null);
  const [startingVolumeGreaterThan2Count, setStartingVolumeGreaterThan2Count] = useState(null);
  const [endingVolumeGreaterThan2Count, setEndingingVolumeGreaterThan2Count] = useState(null);
  const [durationOver60Count, setDurationOver60Count] = useState(null);
  const [durationUnder5Count, setDurationUnder5Count] = useState(null);
  const [durationNullCount, setDurationNullCount] = useState(null);
  const [totalPassCount, setTotalPassCount] = useState(null);
  const [totalPassRate, setTotalPassRate] = useState(null);

  useEffect(() => {
    const uniqTicketIds = (previous: number[], current: Event) => {
      return !previous.includes(current.ticketId)
        ? previous.concat(current.ticketId)
        : previous;
    };

    const totalTicketCount = events.reduce(uniqTicketIds, []).length;

    const negativeVolumeTicketIds = filter(negativeVolumeTicketIdsFilter, events)
      .reduce(uniqTicketIds, []);

    const startingVolumeGreaterThan2 = filter(startingVolumeGreaterThan2Filter, events)
      .reduce(uniqTicketIds, []);

    const endingVolumeGreaterThan2 = filter(endingVolumeGreaterThan2Filter, events)
      .reduce(uniqTicketIds, []);

    const durationOver60TicketIds = filter(durationOver60TicketIdsFilter, events)
      .reduce(uniqTicketIds, []);

    const durationUnder5TicketIds = filter(durationUnder5TicketIdsFilter, events)
      .reduce(uniqTicketIds, []);

    const durationNullTicketIds = filter(durationNullFilter, events)
      .reduce(uniqTicketIds, []);

    const totalFailTicketIds = uniq([
      ...startingVolumeGreaterThan2,
      ...endingVolumeGreaterThan2,
      ...negativeVolumeTicketIds,
      ...durationOver60TicketIds,
      ...durationUnder5TicketIds,
      ...durationNullTicketIds,
    ]);

    const totalFailCount = totalFailTicketIds.length;
    const ticketCount = events.reduce(uniqTicketIds, []).length;
    const totalPassCount = ticketCount - totalFailCount;
    const totalPassRate = `${round(((totalPassCount / ticketCount) || 0) * 100, 1)}%`;

    setTotalTicketCount(totalTicketCount);
    setNegativeVolumeCount(negativeVolumeTicketIds.length);
    setStartingVolumeGreaterThan2Count(startingVolumeGreaterThan2.length);
    setEndingingVolumeGreaterThan2Count(endingVolumeGreaterThan2.length);
    setDurationOver60Count(durationOver60TicketIds.length);
    setDurationUnder5Count(durationUnder5TicketIds.length);
    setDurationNullCount(durationNullTicketIds.length);
    setTotalPassCount(totalPassCount);
    setTotalPassRate(totalPassRate);
  }, [events]);

  const toggle = async (filterType) => {
    toggleFilterAccuracy(filterType);
  }

  const buildFilterableColumn = (count, filterType) => {
    if (count > 0) {
      const exists = accuracyFilterExists(filterType);
      if (loadingFields.includes(filterType)) {
        return <TableCell>loading</TableCell>
      }
      return (
        <TableCell positive={exists} onClick={() => toggle(filterType)}>
            <div className={styles.whitespace}>
              <a>
                <u>{count}</u>
              </a> {exists && <a>[<u>clear</u>]</a>}
            </div>
          </TableCell>
      )
    }
    return (<TableCell>{count}</TableCell>);
  }

  return (
    // @ts-ignore
    <Table celled style={style}>
      <TableHeader>
        <TableRow>
          <TableHeaderCell>{'Total Tickets'}</TableHeaderCell>
          <TableHeaderCell>{'Negative Volume'}</TableHeaderCell>
          <TableHeaderCell>{'Start Volume > 2'}</TableHeaderCell>
          <TableHeaderCell>{'End Volume > 2'}</TableHeaderCell>
          <TableHeaderCell>{'Duration Over 60'}</TableHeaderCell>
          <TableHeaderCell>{'Duration Under 5'}</TableHeaderCell>
          <TableHeaderCell>{'Duration Null'}</TableHeaderCell>
          <TableHeaderCell>{'Total Pass'}</TableHeaderCell>
          <TableHeaderCell>{'Total Pass Rate'}</TableHeaderCell>
        </TableRow>
      </TableHeader>
      <TableBody>
        <TableRow>
          <TableCell>{totalTicketCount}</TableCell>
          {buildFilterableColumn(negativeVolumeCount, ACCURACY_FILTER_NEGATIVE_VOLUME)}
          {buildFilterableColumn(startingVolumeGreaterThan2Count, ACCURACY_FILTER_START_VOLUME_GREATER_THAN_2)}
          {buildFilterableColumn(endingVolumeGreaterThan2Count, ACCURACY_FILTER_END_VOLUME_GREATER_THAN_2)} 
          {buildFilterableColumn(durationOver60Count, ACCURACY_FILTER_DURATION_OVER_60)}
          {buildFilterableColumn(durationUnder5Count, ACCURACY_FILTER_DURATION_UNDER_5)}
          {buildFilterableColumn(durationNullCount, ACCURACY_FILTER_NULL_DURATION)}

          <TableCell>{totalPassCount}</TableCell>
          <TableCell>{totalPassRate}</TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};

const styles = {
  whitespace: css({
    whiteSpace: 'nowrap',
    cursor: 'pointer',
  }),

};

