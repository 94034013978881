import {css} from '@emotion/css';
import {Route, Routes} from 'react-router-dom';
import {Loader} from './components';
import {useAuthRoute} from './hooks';
import {
  AdminPage,
  LandingPage,
  LoginPage,
  NotFoundPage,
  ProfilePage,
  SitesPage,
  TicketDetailPage,
  TrailersPage,
  // UserAddPage,
  // UserEditPage,
} from './pages';
import { TrailerStatusPage } from './pages/TrailerStatusPage';

const styles = {
  loaderContainer: css({
    height: '100vh',
  }),
};

export const App = () => {
  const {loading} = useAuthRoute();

  return loading ? (
    <div className={styles.loaderContainer}>
      <Loader />
    </div>
  ) : (
    <Routes>
      <Route path={'/'} element={<LandingPage />} key={'home'} />
      <Route path={'/login'} element={<LoginPage />} key={'login'} />
      <Route path={'/profile'} element={<ProfilePage />} key={'profile'} />
      {/* TODO: reenable once we have a way to fetch all users again */}
      {/* <Route path={'/admin'} element={<AdminPage />} key={'admin'} /> */}
      <Route
        path={'/ticket/:ticketId'}
        element={<TicketDetailPage />}
        key={'ticket'}
      />
      <Route path={'/trailers'} element={<TrailersPage />} key={'trailers'} />
      <Route path={'/sites'} element={<SitesPage />} key={'sites'} />
      <Route path={'/trailerstatus'} element={<TrailerStatusPage />} key={'trailerstatus'} />
      {/* <Route path={'/user/new'} element={<UserAddPage />} key={'userNew'} /> */}
      {/* <Route path={'/user/:userId'} element={<UserEditPage />} key={'user'} /> */}
      <Route path="*" element={<NotFoundPage />} key={'notFound'} />
    </Routes>
  );
};
