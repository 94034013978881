import {css} from '@emotion/css';
import {uniq} from 'ramda';
import {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {Icon} from 'semantic-ui-react';
import {TicketChart, TicketMap, TicketTable, Page} from '../components';
import {
  useActions,
  useAppSelector,
  useAuth,
  useRawTicketData,
  useTicketData,
  useTimeZoneMessage,
} from '../hooks';

const styles = {
  grid: css`
    display: grid;
    padding: 20px;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 20px;
    @media (max-width: 719px) {
      grid-template-areas:
        'b b'
        'c c'
        'm m'
        'z z'
        't t';
    }
    @media (min-width: 720px) {
      grid-template-areas:
        'b b'
        'c m'
        'z z'
        't t';
    }
  `,
  gridAreaButtons: css({
    gridArea: 'b',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }),
  gridAreaChart: css({
    gridArea: 'c',
  }),
  gridAreaMap: css({
    gridArea: 'm',
  }),
  gridAreaTimeZoneMessage: css({
    gridArea: 'z',
  }),
  gridAreaTable: css({
    gridArea: 't',
  }),
  chartContainer: css({
    height: '100%',
    width: '100%',
    minHeight: '200px',
  }),
  mapContainer: css({
    height: '100%',
    minHeight: '200px',
    width: '100%',
  }),
  tableContainer: css({
    overflowX: 'scroll',
  }),
};

export const TicketDetailPage = () => {
  const navigate = useNavigate();
  const params = useParams();
  const { user } = useAuth();
  const ticketId = Number(params.ticketId);
  const {
    finalEvents,
    ticketsTableParams: {timeZone},
  } = useAppSelector(state => state.app);
  const [ticketIds, setTicketIds] = useState([ticketId]);
  const [position, setPosition] = useState(0);
  const {timeZoneMessage} = useTimeZoneMessage();
  const {rawTicket, loading} = useRawTicketData(ticketId);
  const {events} = useTicketData(ticketId);

  const {setPageName} = useActions();

  useEffect(() => {
    setPageName('Ticket Detail');
  }, [setPageName]);
  
  useEffect(() => {
    const ticketIds = uniq(finalEvents.map(event => event.ticketId));
    const position = ticketIds.indexOf(ticketId);
    setTicketIds(ticketIds);
    setPosition(position);
  }, [finalEvents]);

  const onClickLeft = () => {
    const nextPosition = position - 1;
    navigate(`/ticket/${ticketIds[nextPosition]}`);
    setPosition(nextPosition);
  };

  const onClickRight = () => {
    const nextPosition = position + 1;
    navigate(`/ticket/${ticketIds[nextPosition]}`);
    setPosition(nextPosition);
  };

  return (
    <Page>
      <div className={styles.grid}>
        <div className={styles.gridAreaButtons}>
          <Icon
            name="angle left"
            size="large"
            style={{cursor: 'pointer'}}
            disabled={[0, -1].includes(position)}
            onClick={onClickLeft}
          />
          <span style={{fontSize: '16px', margin: '0 10px'}}>{ticketId}</span>
          <Icon
            name="angle right"
            size="large"
            style={{cursor: 'pointer'}}
            disabled={position === ticketIds.length - 1}
            onClick={onClickRight}
          />
        </div>
        <div className={styles.gridAreaChart}>
          <div className={styles.chartContainer}>
            <TicketChart rawTicket={rawTicket} loading={loading} events={events} />
          </div>
        </div>
        <div className={styles.gridAreaMap}>
          <div className={styles.mapContainer}>
            <TicketMap
              rawTicket={rawTicket}
              events={events}
              loading={loading}
            />
          </div>
        </div>
        <div className={styles.gridAreaTimeZoneMessage}>{timeZoneMessage}</div>
        <div className={styles.gridAreaTable}>
          <div className={styles.tableContainer}>
            <TicketTable events={events} timeZone={timeZone} />
          </div>
        </div>
      </div>
    </Page>
  );
};
