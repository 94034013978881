import { css } from '@emotion/css';
import { isNil, reverse, sort, uniq, filter } from 'ramda';
import { useCallback, useEffect, useState } from 'react';
import { DateInput } from 'semantic-ui-calendar-react';
import { Button, Checkbox, Dropdown, Pagination } from 'semantic-ui-react';
import {
  ColumnFilterDropdown,
  ExportButton,
  AccuracyReportTable,
  TicketsTable,
  Page,
} from '../components';
import { ACCURACY_FILTER_DURATION_OVER_60, ACCURACY_FILTER_DURATION_UNDER_5, ACCURACY_FILTER_END_VOLUME_GREATER_THAN_2, ACCURACY_FILTER_NEGATIVE_VOLUME, ACCURACY_FILTER_NULL_DURATION, ACCURACY_FILTER_START_VOLUME_GREATER_THAN_2 } from '../helpers/constants';
import { TimeZone } from '../enums';
import {
  useActions,
  useAppSelector,
  useTicketsData,
  useTimeZoneMessage,
} from '../hooks';

import {
  durationNullFilter, durationOver60TicketIdsFilter, durationUnder5TicketIdsFilter, endingVolumeGreaterThan2Filter, negativeVolumeTicketIdsFilter, startingVolumeGreaterThan2Filter
} from '../helpers/filters';

const pageSizes = [{
  key: '100',
  value: 100,
  description: '100',
}, {
  key: '250',
  value: 250,
  description: '250',
}, {
  key: '500',
  value: 500,
  description: '500',
}];

export const styles = {
  grid: css`
    display: grid;
    padding: 20px;
    grid-template-columns: repeat(12, 1fr);
    gap: 20px;
    @media (max-width: 719px) {
      grid-template-areas:
        'hc hc hc hc hc hc hc hc hc hc hc hc'
        'fh fh fh fh fh fh fh fh fh fh fh fh'
        'ft ft ft ft ft ft ft ft ft ft ft ft'
        'fs fs fs fs fs fs fs fs fs fs fs fs'
        'sd sd sd sd sd sd sd sd sd sd sd sd'
        'ed ed ed ed ed ed ed ed ed ed ed ed'
        'tz tz tz tz tz tz tz tz tz tz tz tz'
        'cr cr cr cr cr cr cr cr cr cr cr cr'
        'tm tm tm tm tm tm tm tm tm tm tm tm'
        'sr sr sr sr sr sr sr sr sr sr sr sr'
        'eb eb eb eb eb eb eb eb eb eb eb eb'
        'ac ac ac ac ac ac ac ac ac ac ac ac'
        'tt tt tt tt tt tt tt tt tt tt tt tt';
    }
    @media (min-width: 720px) {
      grid-template-areas:
        'hc hc hc hc hc hc hc hc hc sr eb  eb'
        'fh fh fh ft ft ft fs fs fs . .  .'
        'sd sd sd ed ed ed tz tz tz .  .  .'
        'cr cr cr ac ac ac dc dc da da  .  .'
        'tm tm tm tm tm tm tm tm tm tm tm tm'
        'tt tt tt tt tt tt tt tt tt tt tt tt';
    }
  `,
  gridAreaHideColumnsDropdown: css({
    gridArea: 'hc',
  }),
  gridAreaSearchButton: css({
    gridArea: 'sr',
  }),
  gridAreaExportButton: css({
    gridArea: 'eb',
  }),
  gridAreaFilterHaulerDropdown: css({
    gridArea: 'fh',
  }),
  gridAreaFilterTrailerIdDropdown: css({
    gridArea: 'ft',
  }),
  gridAreaFilterSiteDropdown: css({
    gridArea: 'fs',
  }),
  gridAreaStartDateInput: css({
    gridArea: 'sd',
  }),
  gridAreaEndDateInput: css({
    gridArea: 'ed',
  }),
  gridAreaTimeZoneDropdown: css({
    gridArea: 'tz',
    display: 'flex',
    alignItems: 'center',
  }),
  gridAreaCombineRowsCheckbox: css({
    gridArea: 'cr',
    display: 'flex',
    alignItems: 'center',
  }),
  gridAreaAccuracyReportCheckbox: css({
    gridArea: 'ac',
    display: 'flex',
    alignItems: 'center',
  }),
  gridAreaAccuracySearchButton: css({
    display: 'flex',
    alignItems: 'center',
  }),
  gridAreaTimeZoneMessage: css({
    gridArea: 'tm',
  }),
  gridAreaTicketsTable: css({
    gridArea: 'tt',
  }),
  searchButton: css`
    @media (max-width: 719px) {
      width: 100%;
    }
    @media (min-width: 720px) {
      float: right;
    }
  `,
  exportButton: css`
    @media (max-width: 719px) {
      width: 100%;
    }
    @media (min-width: 720px) {
      float: right;
    }
  `,
};

const emptyFilter = {
  key: 'empty',
  text: 'All',
  value: '',
};

type sortDirection = 'ascending' | 'descending' | null;

const timeZones = [
  TimeZone.UTC,
  TimeZone.UTCm4,
  TimeZone.UTCm5,
  TimeZone.UTCm6,
  TimeZone.UTCm7,
  TimeZone.UTCm8,
].map(tz => ({
  key: tz,
  value: tz,
  text: tz,
}));

export const LandingPage = () => {
  const { events, columns, loading, fetchAndStoreData } = useTicketsData();

  const {
    finalEvents,
    ticketsTableParams: {
      hiddenColumns,
      startDate,
      endDate,
      haulerFilter,
      trailerIdFilter,
      siteFilter,
      timeZone,
      combineRows,
      showAccuracyReport,
    },
  } = useAppSelector(state => state.app);

  const {
    setFinalEvents,
    setHiddenColumns,
    setStartDate,
    setEndDate,
    setHaulerFilter,
    setTrailerIdFilter,
    setSiteFilter,
    setCombineRows,
    setTimeZone,
    setShowAccuracyReport,
    setPageName,
  } = useActions();

  const [visibleColumns, setVisibleColumns] = useState(columns);
  const [visibleEvents, setVisibleEvents] = useState([]);
  const [exportData, setExportData] = useState([]);
  const [trailerIds, setTrailerIds] = useState([]);
  const [haulers, setHaulers] = useState([]);
  const [sites, setSites] = useState([]);
  const [sortColumn, setSortColumn] = useState(null);
  const [sortDirection, setSortDirection] = useState<sortDirection>(null);
  const { timeZoneMessage } = useTimeZoneMessage();
  const [filterAccuracyColumns, setFilterAccuracyColumns] = useState([]);
  const [loadingFields, ] = useState([]);
  const [activePage, setActivePage] = useState(0);
  const [eventsPerPage, setEventsPerPage] = useState(100);

  useEffect(() => {
    const visibleColumns = filter(({ key }) => !hiddenColumns.includes(key), columns);
    setVisibleColumns(visibleColumns);
  }, [columns, hiddenColumns]);

  useEffect(() => {
    const exportData = finalEvents.map(event => {
      const obj = {};
      visibleColumns.forEach(
        ({ key: columnKey, display, useDisplayValueInExport }) => {
          const rawValue = event[columnKey];
          const value =
            useDisplayValueInExport && !isNil(display) && !isNil(rawValue)
              ? display(rawValue, { timeZone })
              : rawValue;
          obj[columnKey] = value;
        },
      );
      return obj;
    });
    setExportData(exportData);
  }, [finalEvents, visibleColumns, timeZone]);

  useEffect(() => {
    if (visibleEvents && visibleEvents.length > 0) {
      const haulers = uniq(
        visibleEvents
          .map(event => event.hauler)
          .filter(hauler => hauler)
          .sort(),
      ).map(hauler => ({
        key: hauler,
        text: hauler,
        value: hauler,
      }));
      setHaulers([emptyFilter, ...haulers]);
    }
  }, [visibleEvents]);

  useEffect(() => {
    if (visibleEvents && visibleEvents.length > 0) {
      const trailerIds = uniq(
        visibleEvents
          .map(event => event.trailerId)
          .sort((a, b) => {
            return Number(a) - Number(b);
          }),
      ).map(trailerId => ({
        key: trailerId,
        text: trailerId,
        value: trailerId,
      }));
      setTrailerIds([emptyFilter, ...trailerIds]);
    }
  }, [visibleEvents]);

  useEffect(() => {
    if (visibleEvents && visibleEvents.length > 0) {
      const sites = uniq(
        visibleEvents
          .map(event => event.site)
          .filter(site => site)
          .sort(),
      ).map(site => ({
        key: site,
        text: site,
        value: site,
      }));
      setSites([emptyFilter, ...sites]);
    }
  }, [visibleEvents]);

  const filterResults = useCallback(() => {
    return filter(event => (
      (!trailerIdFilter || event.trailerId === trailerIdFilter) &&
      (!haulerFilter || event.hauler === haulerFilter) &&
      (!siteFilter || event.site === siteFilter)),
      events
    );
  }, [events])

  const filterAccuracy = useCallback(() => {
    let visibleEvents = filterResults();

    filterAccuracyColumns.map(column => {
      setActivePage(1);
      switch (column) {
        case ACCURACY_FILTER_NULL_DURATION:
          visibleEvents = filter(durationNullFilter, visibleEvents);
          break;

        case ACCURACY_FILTER_DURATION_UNDER_5:
          visibleEvents = filter(durationUnder5TicketIdsFilter, visibleEvents);
          break;

        case ACCURACY_FILTER_DURATION_OVER_60:
          visibleEvents = filter(durationOver60TicketIdsFilter, visibleEvents);
          break;

        case ACCURACY_FILTER_NEGATIVE_VOLUME:
          visibleEvents = filter(negativeVolumeTicketIdsFilter, visibleEvents);
          break;

        case ACCURACY_FILTER_START_VOLUME_GREATER_THAN_2:
          visibleEvents = filter(startingVolumeGreaterThan2Filter, visibleEvents);
          break;

        case ACCURACY_FILTER_END_VOLUME_GREATER_THAN_2:
          visibleEvents = filter(endingVolumeGreaterThan2Filter, visibleEvents);
          break;
      }
    })

    // const negativeVolumeTicketIds = events
    //   .filter(event => event.netVolume < 0)
    //   .reduce(uniqTicketIds, []);

    // const durationOver60TicketIds = events
    //   .filter(event => event.duration > minutesToSeconds(60))
    //   .reduce(uniqTicketIds, []);

    // const durationUnder5TicketIds = events
    //   .filter(event => event.duration < minutesToSeconds(5))
    //   .reduce(uniqTicketIds, []);

    // const durationNullTicketIds = events
    //   .filter(event => isNil(event.duration) || event.duration < minutesToSeconds(1))
    //   .reduce(uniqTicketIds, []);


    setVisibleEvents(visibleEvents);
  }, [filterAccuracyColumns, filterResults]);

  useEffect(() => {
    setPageName('Dashboard');
    fetchAndStoreData();
    setActivePage(1);
  }, []);

  useEffect(() => {
    filterAccuracy();
  }, [filterAccuracy, filterAccuracyColumns]);

  const toggleFilterAccuracy = filter => {
    if (filterAccuracyColumns.includes(filter)) {
      setFilterAccuracyColumns(filterAccuracyColumns.filter(f => f !== filter));
    } else {
      setFilterAccuracyColumns([...filterAccuracyColumns, filter]);
    }
  }

  const accuracyFilterExists = filter => filterAccuracyColumns.includes(filter);

  useEffect(() => {
    if (!sortColumn) {
      setFinalEvents(visibleEvents);
      return;
    }

    const sortedEvents = sort((eventA, eventB) => {
      // deviceSerialNumber is a string but should be sorting like a number field
      if (sortColumn === 'deviceSerialNumber') {
        const eventAValue = Number(
          (eventA[sortColumn] || '').split('_')[2] || 0,
        );
        const eventBValue = Number(
          (eventB[sortColumn] || '').split('_')[2] || 0,
        );
        return eventAValue - eventBValue;
      }

      const eventAValue = eventA[sortColumn];
      const eventBValue = eventB[sortColumn];

      // potential no property of undefined bug
      const columnDataType = columns.find(
        column => column.key === sortColumn,
      ).type;
      return columnDataType === 'number'
        ? (eventAValue || 0) - (eventBValue || 0)
        : (eventAValue || '').localeCompare(eventBValue || '');
    })(visibleEvents);
    // can I take advantage of currying?
    const orderedEvents =
      sortDirection === 'ascending' ? sortedEvents : reverse(sortedEvents);
    setFinalEvents(orderedEvents);
  }, [visibleEvents, sortColumn, sortDirection]);

  const onChangeHideColumnsDropdown = value => {
    setHiddenColumns(value);
  };

  const onChangeHaulerFilterDropdown = (e, { value }) => {
    setHaulerFilter(value);
  };

  const onChangeTrailerIdFilterDropdown = (e, { value }) => {
    setTrailerIdFilter(value);
  };

  const onChangeSiteFilterDropdown = (e, { value }) => {
    setSiteFilter(value);
  };

  const onChangeTimeZone = (e, { value }) => {
    setTimeZone(value);
  };

  const onChangeCombineRowsCheckbox = (e, { checked }) => {
    setSortColumn(null);
    setSortDirection(null);
    setCombineRows(checked);
  };

  const onChangeAccuracyReportCheckbox = (e, { checked }) => {
    setShowAccuracyReport(checked);
  };

  const onClickHeaderCell = columnKey => {
    const column = columns.find(column => column.key === columnKey);
    if (!column.span) {
      setCombineRows(false);
    }

    const prevSortColumn = sortColumn;
    const prevSortDirection = sortDirection;
    const sortColumnDidChange = columnKey !== prevSortColumn;
    const updatedSortDirection = sortColumnDidChange
      ? 'ascending'
      : prevSortDirection === 'ascending'
        ? 'descending'
        : 'ascending';
    setSortColumn(columnKey);
    setSortDirection(updatedSortDirection);
  };

  const changePage = (e, page) => {
    setActivePage(page.activePage);
  }

  const filterDropdowns = [
    {
      placeholder: 'Hauler',
      options: haulers,
      value: haulerFilter,
      onChange: onChangeHaulerFilterDropdown,
      style: styles.gridAreaFilterHaulerDropdown,
    },
    {
      placeholder: 'Trailer Id',
      options: trailerIds,
      value: trailerIdFilter,
      onChange: onChangeTrailerIdFilterDropdown,
      style: styles.gridAreaFilterTrailerIdDropdown,
    },
    {
      placeholder: 'Site',
      options: sites,
      value: siteFilter,
      onChange: onChangeSiteFilterDropdown,
      style: styles.gridAreaFilterSiteDropdown,
    },
  ];

  const dateInputs = [
    {
      key: 'startDate',
      value: startDate,
      placeholder: 'Start Date',
      onChange: (e, data) => {
        setStartDate(data.value);
      },
      style: styles.gridAreaStartDateInput,
    },
    {
      key: 'endDate',
      value: endDate,
      placeholder: 'End Date',
      onChange: (e, data) => {
        setEndDate(data.value);
      },
      style: styles.gridAreaEndDateInput,
    },
  ];

  return (
    <Page>
      <div style={{ display: 'flex', flex: 1, flexDirection: 'row' }}>
        <div style={{ width: '100vw', overflow: 'hidden', display: 'flex', flexDirection: 'column' }}>
          <div id="dropdownoptions" style={{ position: 'sticky', flex: 0 }}>
            <div className={styles.grid}>
              <div className={styles.gridAreaHideColumnsDropdown}>
                <ColumnFilterDropdown
                  columns={columns}
                  onChange={onChangeHideColumnsDropdown}
                />
              </div>
              <div className={styles.gridAreaExportButton}>
                <ExportButton
                  className={styles.exportButton}
                  json={exportData}
                  columns={visibleColumns}
                  filename="tickets"
                />
              </div>
              {filterDropdowns.map(
                ({ placeholder, options, value, onChange, style }, index) => (
                  <div className={style} key={index}>
                    <Dropdown
                      style={{ width: '100%', minWidth: 0 }}
                      selection
                      placeholder={placeholder}
                      options={options}
                      value={value}
                      onChange={onChange}
                    />
                  </div>
                ),
              )}
              {dateInputs.map(({ placeholder, value, onChange, key, style }) => (
                <div className={style} key={key}>
                  <DateInput
                    style={{ width: '100%', minWidth: 0 }}
                    name="date"
                    placeholder={placeholder}
                    value={value}
                    iconPosition="left"
                    popupPosition="bottom center"
                    dateFormat={'M/D/YYYY'}
                    onChange={onChange}
                  />
                </div>
              ))}
              <div className={styles.gridAreaTimeZoneDropdown}>
                <Dropdown
                  style={{ width: '100%', minWidth: 0 }}
                  selection
                  placeholder="Time Zone"
                  options={timeZones}
                  value={timeZone}
                  onChange={onChangeTimeZone}
                />
              </div>
              <div className={styles.gridAreaSearchButton}>
                <Button primary className={styles.searchButton} onClick={() => {
                  setActivePage(1);
                  fetchAndStoreData();
                }}>Search</Button>
              </div>
              <div className={styles.gridAreaCombineRowsCheckbox}>
                <Checkbox
                  label="Combine Rows"
                  onChange={onChangeCombineRowsCheckbox}
                  checked={combineRows}
                />
              </div>
              <div className={styles.gridAreaAccuracyReportCheckbox}>
                <Checkbox
                  label="Accuracy Report"
                  onChange={onChangeAccuracyReportCheckbox}
                  checked={showAccuracyReport}
                />
              </div>
              <div className={styles.gridAreaTimeZoneMessage}>{timeZoneMessage}</div>
              <div className={styles.gridAreaTicketsTable}>
                {showAccuracyReport && (
                  <AccuracyReportTable
                    events={finalEvents}
                    toggleFilterAccuracy={toggleFilterAccuracy}
                    accuracyFilterExists={accuracyFilterExists}
                    loadingFields={loadingFields}
                    style={{ marginBottom: '20px' }}
                  />
                )}
              </div>
            </div>
          </div>
          <div style={{ flex: 1, overflow: 'auto' }}>
            <TicketsTable
              loading={loading}
              columns={visibleColumns}
              events={finalEvents ? finalEvents.slice(((activePage - 1 ) * 100), (activePage - 1) * 100 + 100) : []}
              combineRows={combineRows}
              timeZone={timeZone}
              sortColumn={sortColumn}
              sortDirection={sortDirection}
              onClickHeaderCell={onClickHeaderCell}
            />
          </div>
          <div>
            <div style={{ flex: 1, display: 'flex', justifyContent: 'center' }}>
            <Pagination
                activePage={activePage}
                onPageChange={changePage}
                pointing
                totalPages={Math.ceil(finalEvents.length / 100)}
              />
            </div>
          </div>
        </div>
      </div>
    </Page>
  );
};
