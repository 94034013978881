export const siteTableColumns = [
  {
    key: 'name',
    title: 'Name',
  },
  {
    key: 'type',
    title: 'Type',
  },
  {
    key: 'ownerName',
    title: 'Owner Name',
  },
  {
    key: 'latitude',
    title: 'Latitude',
  },
  {
    key: 'longitude',
    title: 'Longitude',
  },
];
