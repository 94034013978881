export const trailerTableColumns = [
  {
    key: 'id',
    title: 'ID',
  },
  {
    key: 'haulerName',
    title: 'Hauler',
  },
  {
    key: 'eagleSerialNumber',
    title: 'Eagle Serial Number',
  },
  {
    key: 'vin',
    title: 'VIN',
  },
  {
    key: 'trailerIdentifier',
    title: 'Trailer Identifier',
  },
  {
    key: 'installDate',
    title: 'Install Date',
  },
  {
    key: 'sensorSerialNumber',
    title: 'Sensor Serial Number',
  },
  {
    key: 'sensorCode',
    title: 'Sensor Code',
  },
  {
    key: 'particleDeviceId',
    title: 'Particle Device ID',
  },
];
