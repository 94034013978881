import {useState, useEffect} from 'react';
import {useAuth} from './useAuth';
import {fetchLoggedInUser} from '../api';
import {Model, User} from '../models';

export const useLoggedInUserData = () => {
  const {authToken} = useAuth();
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState<User>();

  useEffect(() => {
    if (!authToken) return;
    setLoading(true);

    fetchLoggedInUser({authToken})
      .then(response => {
        const user = Model.createUserFromApiObject(response.data);
        setUser(user);
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [authToken]);

  return {user, loading};
};
