import {css} from '@emotion/css';
import {isNil, filter, includes} from 'ramda';
import {Link} from 'react-router-dom';
import {
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHeaderCell,
  TableRow,
} from 'semantic-ui-react';
import {Loader} from '../components';
import {TimeZone} from '../enums';
import {Event} from '../models';

const styles = {
  loaderContainer: css({
    height: '120px',
  }),
  container: css({
    overflowX: 'scroll',
    height: '100%',
    display: 'flex',
    flex: 1,
  }),
  table: css({
    marginTop: 0,
  }),
};

interface TicketsTableProps {
  loading: boolean;
  columns: any[];
  events: Event[];
  combineRows: boolean;
  timeZone: TimeZone;
  sortColumn?: string;
  sortDirection?: string;
  onClickHeaderCell: (columnKey: string) => void;
}

export const TicketsTable = ({
  loading,
  columns,
  events,
  combineRows,
  timeZone,
  sortColumn,
  sortDirection,
  onClickHeaderCell,
}: TicketsTableProps) => {
  const determineCellVisibility = (columnKey, event) => {
    if (!combineRows) {
      return true;
    }

    const eventsOfSameTicket = filter(
      ({ticketId}) => ticketId === event.ticketId, events
    );
    const hasMultipleEvents = eventsOfSameTicket.length > 1;

    if (!hasMultipleEvents) {
      return true;
    }
    const firstId = eventsOfSameTicket[0].id;
    if (event.id !== firstId && includes(columnKey, spannableColumnKeys)) {
      return false;
    } else {
      return true;
    }
  };

  const spannableColumnKeys = filter(column => column.span, columns)
    .map(column => column.key);

  const rowSpan = (columnKey, ticketId) => {
    if (!combineRows) {
      return 1;
    } else if (!includes(columnKey, spannableColumnKeys)) {
      return 1;
    } else {
      const span = filter(event => event.ticketId === ticketId, events).length;
      return span;
    }
  };


  return loading ? (
    <div className={styles.loaderContainer}>
      <Loader />
    </div>
  ) : (
    <div className={styles.container}>
      {/* @ts-ignore */}
      <Table celled sortable structured unstackable className={styles.table}>
        <TableHeader>
          <TableRow>
            {columns.map(({key, title}) => {
              return (
              <TableHeaderCell
                sorted={sortColumn === key ? (sortDirection === 'descending' ? 'descending' : undefined) : undefined}
                onClick={() => onClickHeaderCell(key)}
                key={key}>
                {title}
              </TableHeaderCell>
              )})}
          </TableRow>
        </TableHeader>
        <TableBody>
          {events.map((event, index) => (
            <TableRow key={event.id}>
              {columns.map(({key: columnKey, display}) => {
                const showCell = determineCellVisibility(columnKey, event);

                const value = event[columnKey];
                const displayValue =
                  !isNil(value) && !isNil(display)
                    ? display(value, {timeZone})
                    : value;

                return showCell ? (
                  <TableCell
                    rowSpan={rowSpan(columnKey, event.ticketId)}
                    key={`${event.id}-${columnKey}-${index}`}>
                    {columnKey === 'ticketId' ? (
                      <Link to={`ticket/${event.ticketId}`}>
                        {displayValue}
                      </Link>
                    ) : (
                      displayValue
                    )}
                  </TableCell>
                ) : null;
              })}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};
