import React, { useState } from 'react';
import { Button, Dropdown, Grid, Icon, Menu, Segment, Sidebar } from 'semantic-ui-react';
import { getAuth } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { useAppSelector, useAuth } from '../hooks';
import { LogoInlineSvg } from '../svgs';
import { css } from '@emotion/css';

interface PageProps {
  contentContainerStyle?: any;
  contentContainerProps?: any;
  children?: React.ReactNode;
}

const styles = {
  grid: css`
    display: grid;
    padding: 20px;
    grid-template-columns: repeat(6, minmax(0, 1fr));
    gap: 20px;
    @media (max-width: 719px) {
      grid-template-areas: 't t t';
    }
    @media (min-width: 720px) {
      grid-template-areas: 't . t';
    }
  `,
  gridAreaTable: css({
    gridArea: 't',
  }),
};

export const Page = ({
  contentContainerStyle,
  contentContainerProps,
  children,
}: PageProps) => {
  const { user: fbUser } = useAuth();
  const navigate = useNavigate();
  const [visible, setVisible] = useState(false);
  const {
    pageName
  } = useAppSelector(state => state.app);

  const dropdownItems = [
    // ...(user && user.isSuperAdmin
    //   ? [
    //       {
    //         label: 'Admin',
    //         onClick: () => {
    //           navigate('/admin');
    //         },
    //       },
    //     ]
    //   : []),
    {
      label: 'Profile',
      onClick: () => {
        navigate('/profile');
      },
    },
    {
      label: 'Logout',
      onClick: () => {
        getAuth().signOut();
      },
    },
  ];

  const handleClickHome = () => {
    navigate('/');
  };

  return (

    <div style={{ display: 'flex', flexDirection: 'column', overflow: 'hidden', height: '100vh' }}>
      <div
        className={'navbar'}
        style={{
          backgroundColor: '#495468',
          padding: '20px 20px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          flexWrap: 'wrap'
        }}>
        <Grid doubling columns={3} stretched padded={false} relaxed={false} textAlign='center' verticalAlign='middle' style={{ width: '100vw'}}>
          <Grid.Column>
            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'nowrap' }}>
              <div style={{ zIndex: 999, paddingRight: 10 }}>
                <Button circular icon='bars' onClick={() => setVisible(!visible)} inverted={visible} />
              </div>
              <div style={{ zIndex: 999, paddingRight: 10 }}>
                <LogoInlineSvg onClick={handleClickHome} />
              </div>
            </div>
          </Grid.Column>
          <Grid.Column only='computer'>
            <div style={{ color: 'whitesmoke', fontWeight: 800, fontSize: 24 }}>{pageName}</div>
          </Grid.Column>
          <Grid.Column textAlign='right'>
            <Dropdown
              style={{ color: 'whitesmoke', textAlign: 'right' }}
              text={fbUser && fbUser.email}
              pointing
              direction="left">
              <Dropdown.Menu>
                {dropdownItems.map(item => (
                  <Dropdown.Item onClick={item.onClick} key={item.label}>
                    {item.label}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>

          </Grid.Column>
        </Grid>
      </div>
      <div
        className="content"
        style={{ flexGrow: 1, ...contentContainerStyle }}
        {...contentContainerProps}>
        <Sidebar.Pushable as={Segment}>
          <Sidebar
            as={Menu}
            animation='overlay'
            icon='labeled'
            inverted
            color='rgb(73, 84, 104)'
            onHide={() => setVisible(false)}
            vertical
            visible={visible}
            width='thin'
          >
            <Menu.Item as='a' onClick={() => navigate('/')}>
              <Icon name='home' />
              Home
            </Menu.Item>
            <Menu.Item as='a' onClick={() => navigate('/sites')}>
              <Icon name='building' />
              Sites
            </Menu.Item>
            <Menu.Item as='a' onClick={() => navigate('/trailers')}>
              <Icon name='truck' />
              Trailers
            </Menu.Item>
            <Menu.Item as='a' onClick={() => navigate('/trailerstatus')}>
              <Icon name='map marker alternate' />
              Trailer Status
            </Menu.Item>
          </Sidebar>

          <Sidebar.Pusher style={{ height: '100%', overflow: 'auto', maxHeight: 500, display: 'flex' }}>
            {children}
          </Sidebar.Pusher>
        </Sidebar.Pushable>
      </div>
    </div>
  );
};
