
import { isNil } from 'ramda';
import { minutesToSeconds } from 'date-fns';
import { EVENT_TYPE_DISPOSAL, EVENT_TYPE_PICKUP } from './constants';

export const negativeVolumeTicketIdsFilter = event => event.netVolume < 0;
export const durationNullFilter = event => isNil(event.duration) || event.duration < minutesToSeconds(1);
export const startingVolumeGreaterThan2Filter = event => event.startVolume > 2 && event.type === EVENT_TYPE_PICKUP;
export const endingVolumeGreaterThan2Filter = event => event.endVolume > 2 && event.type === EVENT_TYPE_DISPOSAL;
export const durationOver60TicketIdsFilter = event => event.duration > minutesToSeconds(60);
export const durationUnder5TicketIdsFilter = event => event.duration < minutesToSeconds(5);

export default {
  negativeVolumeTicketIdsFilter,
  durationNullFilter,
  startingVolumeGreaterThan2Filter,
  endingVolumeGreaterThan2Filter,
  durationOver60TicketIdsFilter,
  durationUnder5TicketIdsFilter
};

