import {
  GoogleMap,
  useJsApiLoader,
  Marker,
  Polyline,
} from '@react-google-maps/api';
import {useEffect, useState} from 'react';
import env from '../env.json';
import {Loader} from '../components';
import {Event, RawTicket} from '../models';
import {computeCenter} from '../utils';

// @ts-ignore
const {googleMapsApiKey: apiKey} = env;

interface TicketMapProps {
  rawTicket: RawTicket;
  events: Event[];
  loading: boolean;
}

export const TicketMap = ({rawTicket, events, loading}: TicketMapProps) => {

  const {isLoaded: apiLoaded} = useJsApiLoader({googleMapsApiKey: apiKey});
  const [path, setPath] = useState([]);
  const [, setStartPosition] = useState(null);
  const [, setEndPosition] = useState(null);
  const [center, setCenter] = useState({lat: 0, lng: 0});

  useEffect(() => {
    if (!rawTicket) return;

    const path = [...rawTicket.moments]
      .sort((a, b) => (a.time > b.time ? 1 : -1))
      .map(i => ({lat: i.lat, lng: i.lon}));
    const startPosition = path[0];
    const endPosition = path[path.length - 1];
    const center = computeCenter(path);

    setPath(path);
    setStartPosition(startPosition);
    setEndPosition(endPosition);
    setCenter(center);
  }, [rawTicket]);

  const polylineOptions = {
    strokeColor: '#FF0000',
    strokeOpacity: 0.8,
    strokeWeight: 2,
    clickable: false,
    draggable: false,
    editable: false,
    visible: true,
    radius: 30000,
    path: path,
    zIndex: 10000,
  };

  const markers = [
    ...events.map(event => ({
      position: {lat: event.latitude, lng: event.longitude},
      type: event.type,
    })),
  ];

  const truckFillSvgUrl =
    'https://api.iconify.design/ri/truck-fill.svg?color=%23e00000';
  const truckLineSvgUrl =
    'https://api.iconify.design/ri/truck-line.svg?color=%23e00000';

  return loading || !apiLoaded ? (
    <Loader />
  ) : (
    <GoogleMap
      mapContainerStyle={{height: '100%', width: '100%'}}
      center={center}
      zoom={10}>
      {path.length > 0 && (
        <>
          <Polyline options={polylineOptions} path={polylineOptions.path} />
          {markers.map(marker => (
            <Marker
              position={marker.position}
              icon={{
                url:
                  marker.type === 'Pickup' ? truckFillSvgUrl : truckLineSvgUrl,
                scaledSize: new google.maps.Size(30, 30),
              }}
            />
          ))}
        </>
      )}
    </GoogleMap>
  );
};
