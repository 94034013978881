import {useEffect, useState} from 'react';
import {useAuth} from './';
import {fetchTrailers} from '../api';
import {Model, Trailer} from '../models';

export const useTrailersData = () => {
  const {authToken} = useAuth();
  const [trailers, setTrailers] = useState<Trailer[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!authToken) return;

    setLoading(true);
    fetchTrailers({authToken})
      .then(response => {
        const trailers = response.data.map(item =>
          Model.createTrailerFromApiObject(item),
        );
        setTrailers(trailers);
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return {trailers, loading};
};
