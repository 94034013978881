import {css} from '@emotion/css';
import {formatInTimeZone} from 'date-fns-tz';
import {useEffect, useRef, useState} from 'react';
import {Button, Dropdown, Grid, Icon, Label, Statistic} from 'semantic-ui-react';
import { fetchTrailer } from '../api';
import {Page} from '../components';
import {TrailerMap} from '../components/TrailerMap';
import {useActions, useAuth, useTrailerData, useTrailersData} from '../hooks';

export const TrailerStatusPage = () => {
  const {authToken} = useAuth();
  const {trailers} = useTrailersData();
  const [trailerId, setTrailerId] = useState(undefined);
  const [trailerData, setTrailerData] = useState([]);
  const [lastUpdated, setLastUpdated] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const {trailer, error} = useTrailerData(trailerId);
  const [currentTrailer, setCurrentTrailer] = useState(trailer);
  const [autoRefresh, setAutoRefresh] = useState(undefined);
  const [autoRefreshing, setAutoRefreshing] = useState(undefined);

  const timerRef = useRef(undefined);

  const {setPageName} = useActions();

  const sortTrailers = (a, b) => (a.text < b.text ? -1 : 1);

  const loopTrailers = async () => {
    const data = await trailers
      .map(trailer => {
        return {
          key: trailer.id,
          value: trailer.id,
          text: `${trailer.haulerName} ${trailer.trailerIdentifier}, ${trailer.eagleSerialNumber}`,
        };
      })
      .sort(sortTrailers); 
    setTrailerData(data);
    setLoading(false);
  };

  useEffect(() => {
    if (trailers) {
      setLoading(true);
      loopTrailers();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trailers]);

  useEffect(() => {
    if (trailer) {
      setCurrentTrailer(trailer);
    }
  }, [trailer]);

  useEffect(() => {
    setPageName('Trailer Status');
  }, [setPageName]);

  const changeTrailer = async (event, b) => {
    setLastUpdated(new Date());
    setTrailerId(b.value);
  };

  const refreshTrailer = async () => {
    try {
      const newtrailer = await fetchTrailer({authToken, trailerId})
      if (newtrailer?.data) {
        setCurrentTrailer(newtrailer.data);
        setLastUpdated(new Date());
        setAutoRefresh(true);
      }  
    } catch (e) {
      setAutoRefresh(false);
      console.error(e);
    }
  }

  const formatTime = date => {
    return formatInTimeZone(
      date,
      Intl.DateTimeFormat().resolvedOptions().timeZone,
      'yyyy-MM-dd HH:mm:ss zzz',
    );
  };

  useEffect(() => {
    if (trailerId) {
      startRefresh();
    } else {
      stopRefresh();
    }
  }, [trailerId]);
  
  useEffect(() => {
    if (timerRef.current) {
      clearInterval(timerRef.current);
    }
    setAutoRefresh(true);
  }, []);

  const startRefresh = () => {
    stopRefresh();
    setAutoRefreshing(true);
    timerRef.current = setInterval(() => {
      if(trailerId) {
        refreshTrailer()          
      }
    }, 30000);
  }

  const stopRefresh = () => {
    setAutoRefreshing(false);
    clearInterval(timerRef.current);
    timerRef.current = undefined;

  }

  useEffect(() => {
    if (autoRefresh) {
      startRefresh();
    } else if (autoRefresh === false) {
      stopRefresh();
    }
  }, [autoRefresh]);

  return (
    <Page>
      <div className={styles.container}>
        <div>
          <b>Select a trailer</b>
          <Dropdown
            placeholder="Select Trailer"
            fluid
            search
            selection
            loading={loading}
            options={trailerData}
            onChange={changeTrailer}
          />
        </div>
        {error && (
          <div className={styles.errorContainer}>
            Error retrieving trailer status
          </div>
        )}
        {currentTrailer && (
          <div className={styles.trailerContainer}>
            <div className={styles.trailerWrapper}>
              <div>
                <b>Last Sensor Update</b>
              </div>
              <div>{formatTime(currentTrailer.lastHeard)}</div>
              <Button onClick={refreshTrailer}>Refresh</Button>
              <div>
                <div className={styles.lastUpdated}>Last data pull: {formatTime(lastUpdated)}</div>
              </div>
              <div>
              <div className={styles.lastUpdated}>
                {autoRefreshing && <Icon color="green" name="check circle outline" />}
                {autoRefreshing === false && <Icon color="red" name="times circle outline" />}
                {autoRefreshing === undefined && <Icon loading name='spinner' />}
                Auto Refresh</div>
              </div>
            </div>
            <div className={styles.map}>
              <TrailerMap
                barrels={currentTrailer.barrels}
                position={{lat: currentTrailer?.lat, lng: currentTrailer?.lon}}
                loading={loading}
              />
            </div>
            <div className={styles.statistics}>
              <div>
                <b>Trailer Volume</b>
              </div>
              <div className={styles.bbls}>{`${currentTrailer.barrels} BBLs`}</div>
            </div>
            <div className={styles.gridContainer}>
              <div className={styles.gridWrapper}>
                <Grid
                  columns={3}
                  divided
                  stretched
                  container
                  padded="horizontally">
                  <Grid.Row only='tablet computer'>
                    <Grid.Column>
                      <Statistic size="mini">
                        <Statistic.Label className={styles.statHeader}>
                          Filling Height
                        </Statistic.Label>
                        <Statistic.Value>{currentTrailer.inches}"</Statistic.Value>
                      </Statistic>
                    </Grid.Column>
                    <Grid.Column>
                      <Statistic size="mini">
                        <Statistic.Label className={styles.statHeader}>
                          Cellular %
                        </Statistic.Label>
                        <Statistic.Value>{currentTrailer.cell}%</Statistic.Value>
                      </Statistic>
                    </Grid.Column>
                    <Grid.Column>
                      <Statistic size="mini">
                        <Statistic.Label className={styles.statHeader}>
                          Battery %
                        </Statistic.Label>
                        <Statistic.Value>{currentTrailer.batt}%</Statistic.Value>
                      </Statistic>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
                <Grid
                  columns={3}
                  divided
                  stretched
                  container
                  padded="horizontally">
                  <Grid.Row only='mobile'>
                    <Grid.Column>
                      <Statistic size="mini">
                        <Statistic.Label className={styles.statHeader}>
                          Filling Height
                        </Statistic.Label>
                        <Statistic.Value>{currentTrailer.inches}"</Statistic.Value>
                      </Statistic>
                    </Grid.Column>
                    <Grid.Column>
                      <Statistic size="mini">
                        <Statistic.Label className={styles.statHeader}>
                          Cell. %
                        </Statistic.Label>
                        <Statistic.Value>{currentTrailer.cell}%</Statistic.Value>
                      </Statistic>
                    </Grid.Column>
                    <Grid.Column>
                      <Statistic size="mini">
                        <Statistic.Label className={styles.statHeader}>
                          Bat. %
                        </Statistic.Label>
                        <Statistic.Value>{currentTrailer.batt}%</Statistic.Value>
                      </Statistic>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </div>
            </div>
            <div className={styles.statusContainer}>
              <Label as="div" color={currentTrailer.online ? 'green' : 'red'} image>
                Status: {currentTrailer.online ? 'Online' : 'Offline'}
              </Label>
              <Label as="div" color={currentTrailer.gpsLock ? 'green' : 'red'} image>
                GPS Lock: {currentTrailer.gpsLock ? 'Yes' : 'No'}
              </Label>
            </div>
          </div>
        )}
      </div>
    </Page>
  );
};

const styles = {
  container: css({
    display: 'flex',
    flex: 1,
    width: '100vw',
    flexDirection: 'column',
    padding: 20,
  }),
  errorContainer: css({
    paddingTop: 50,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    fontSize: 18,
    gap: 5,
  }),
  trailerContainer: css({
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    padding: 20,
  }),
  trailerWrapper: css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    fontSize: 18,
    gap: 5,
  }),
  lastUpdated: css({
    fontSize: 10,
  }),
  map: css({
    flex: 1,
    paddingTop: 20,
  }),
  statistics: css({
    display: 'flex',
    padding: 20,
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    fontSize: 18,
    gap: 5,
    paddingTop: 10,
  }),
  bbls: css({
    fontSize: 40,
    fontWeight: '800',
    paddingTop: 10,
    "@media (max-width: 400px)": {
      fontSize: 30
    },

  }),
  gridContainer: css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    fontSize: 18,
    gap: 5,
    paddingTop: 10,
  }),
  gridWrapper: css({
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  }),
  statHeader: css({
    fontSize: 15,
    "@media (max-width: 400px)": {
      fontSize: 30
    },
  }),
  statusContainer: css({
    display: 'flex',
    justifyContent: 'center',
    padding: 20,
  }),
};
