import {TimeZone} from './enums';

/* coordinates */

const rad2degr = rad => (rad * 180) / Math.PI;

const degr2rad = degr => (degr * Math.PI) / 180;

export const computeCenter = positions => {
  let sumX = 0;
  let sumY = 0;
  let sumZ = 0;

  for (var i = 0; i < positions.length; i++) {
    const lat = degr2rad(positions[i].lat);
    const lng = degr2rad(positions[i].lng);
    // sum of cartesian coordinates
    sumX += Math.cos(lat) * Math.cos(lng);
    sumY += Math.cos(lat) * Math.sin(lng);
    sumZ += Math.sin(lat);
  }

  const avgX = sumX / positions.length;
  const avgY = sumY / positions.length;
  const avgZ = sumZ / positions.length;

  // convert average x, y, z coordinate to latitude and longtitude
  const lng = Math.atan2(avgY, avgX);
  const hyp = Math.sqrt(avgX * avgX + avgY * avgY);
  const lat = Math.atan2(avgZ, hyp);

  return {lat: rad2degr(lat), lng: rad2degr(lng)};
};

/* time zones */

export const getCurrentTimeZone = () => {
  const hourOffset = new Date().getTimezoneOffset() / 60;
  switch (hourOffset) {
    case 4:
      return TimeZone.UTCm4;
    case 5:
      return TimeZone.UTCm4;
    case 6:
      return TimeZone.UTCm4;
    case 7:
      return TimeZone.UTCm4;
    case 8:
      return TimeZone.UTCm4;
    default:
      return TimeZone.UTC;
  }
};

export const convertTimeZoneToFormatString = (timeZone: TimeZone) => {
  switch (timeZone) {
    case TimeZone.UTC:
      return 'UTC';
    case TimeZone.UTCm4:
      return '-04';
    case TimeZone.UTCm5:
      return '-05';
    case TimeZone.UTCm6:
      return '-06';
    case TimeZone.UTCm7:
      return '-07';
    case TimeZone.UTCm8:
      return '-08';
  }
};
