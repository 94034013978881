import {
  GoogleMap,
  useJsApiLoader,
  Marker,
} from '@react-google-maps/api';
import env from '../env.json';
import {Loader} from '.';
import { useEffect, useState } from 'react';

// @ts-ignore
const {googleMapsApiKey: apiKey} = env;

interface PositionProps {
  lat: number;
  lng: number;
}
interface TrailerMapProps {
  barrels: number;
  position: PositionProps;
  loading: boolean;
}

export const TrailerMap = ({position, barrels, loading}: TrailerMapProps) => {

  const {isLoaded: apiLoaded} = useJsApiLoader({googleMapsApiKey: apiKey});

  const [currentPosition, setCurrentPosition] = useState(undefined);
  const [center, setCenter] = useState(undefined)

  useEffect(() => {
    setCurrentPosition(position)
    setCenter(position)
  }, [position])

  const truckFillSvgUrl =
    'https://api.iconify.design/ri/truck-fill.svg?color=%23e00000';
  const truckLineSvgUrl =
    'https://api.iconify.design/ri/truck-line.svg?color=%23e00000';

  return loading || !apiLoaded ? (
    <Loader />
  ) : (
    <GoogleMap
      mapContainerStyle={{height: '100%', width: '100%'}}
      center={center}
      zoom={10}>
        <Marker
          position={currentPosition}
          icon={{
            url: barrels < 5 ? truckLineSvgUrl : truckFillSvgUrl,
            scaledSize: new google.maps.Size(30, 30),
          }}
        />
    </GoogleMap>
  );
};
