import {Dropdown} from 'semantic-ui-react';
import {css} from '@emotion/css';

const styles = {
  dropdown: css({
    // width: '600px !important',
  }),
};

export const ColumnFilterDropdown = ({columns, onChange}) => {
  const options = columns.map(({key, title}) => ({
    key,
    text: title,
    value: key,
  }));

  return (
    <Dropdown
      className={styles.dropdown}
      placeholder="Hide Columns"
      fluid
      multiple
      search
      selection
      options={options}
      onChange={(event, data) => onChange(data.value)}
    />
  );
};
