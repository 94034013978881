import {useRef, useState, useEffect} from 'react';
import {useAuth} from './useAuth';
import {fetchTickets} from '../api';
import {eventTableColumns} from '../tableColumns';
import {Model} from '../models';
import {useActions, useAppSelector} from '../hooks';
import { convertTimeZoneToFormatString } from '../utils';
import { TimeZone } from '../enums';
import { format } from 'date-fns';
import moment from 'moment';

export const useTicketsData = () => {
  const {authToken} = useAuth();
  const [loading, setLoading] = useState(false);
  const {
    tickets,
    events,
    ticketsTableParams,
  } = useAppSelector(state => state.app);

  const isValidDate = (dateString: string) => {
    return moment(dateString, "M/D/YYYY", true).isValid();
  }

  const formatDate = (dateString: string, timeZone: TimeZone, endOfDay: boolean) => {
    let date = new Date();
    if (isValidDate(dateString)) {
      date = new Date(dateString);
    }
    if (endOfDay) {
      date.setHours(23, 59, 59, 999);
    } else {
      date.setHours(0, 0, 0, 0);
    }

    const timeZoneFormatString = convertTimeZoneToFormatString(timeZone);
    return format(date, `yyy-MM-dd'T'HH:mm:ss${timeZoneFormatString}00`)
  };

  const { startDate: startDateParam, endDate: endDateParam, timeZone } = ticketsTableParams;
  const {setEvents, setTickets} = useActions();
  const startDateRef = useRef(startDateParam);
  const endDateRef = useRef(endDateParam);

  const fetchAndStoreData = () => {
    if (!authToken) return;

    setLoading(true);
    const startDate =  formatDate(startDateParam, timeZone, false)
    const endDate = formatDate(endDateParam, timeZone, true)

    fetchTickets({ authToken, startDate, endDate})
      .then(response => {
        const tickets = response.data.data
          .map(apiObject => Model.createTicketFromApiObject(apiObject)) 
          .reduce(
            // ensure unique ticket ids
            (prev, curr) =>
              !prev.map(i => i.id).includes(curr.id) ? prev.concat(curr) : prev,
            [],
          );
        const events = tickets
          .map(ticket => Model.convertTicketToEvents(ticket))
          .flat();

        setTickets(tickets);
        setEvents(events);
      })
      .catch(error => {
        console.log('error fetching data\n', error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return {
    tickets,
    events,
    columns: eventTableColumns, // TODO: consider removing
    loading,
    fetchAndStoreData
  };
};
