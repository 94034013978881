export const LogoSvg = () => (
  <svg
    viewBox="162.636 137.697 144.658 125.809"
    xmlns="http://www.w3.org/2000/svg">
    <path
      style={{
        fill: '#012b5d',
        fillOpacity: 1,
        fillRule: 'evenodd',
        stroke: 'none',
      }}
      d="M1167.45 1679.57c73.63-97.51 50.27-170.37 11.86-237.41-17.81.58-41.22 1.13-56.85 7.63l-20.83 8.67 2.12-22.46c.62-6.62 4.88-13.28 10.43-19.12-118.422-44.26-99.28-187.28.56-242.86-65.24 25.51-107.98 88.06-103.15 136.57 14.21 142.59 208.13 181.92 155.86 368.98"
      transform="matrix(.13333 0 0 -.13333 74.368 361.64)"
    />
    <path
      style={{
        fill: '#012b5d',
        fillOpacity: 1,
        fillRule: 'evenodd',
        stroke: 'none',
      }}
      d="M1322.53 1493.23c159.03-277.87-155.53-483.05-306.71-246.02 88.36-317.276 563.25-101.75 306.71 246.02"
      transform="matrix(.13333 0 0 -.13333 74.368 361.64)"
    />
    <path
      style={{
        fill: '#012b5d',
        fillOpacity: 1,
        fillRule: 'evenodd',
        stroke: 'none',
      }}
      d="M1318.39 1302.25c5.75 32.23 6.42 62.78-41.59 77.11-26.5 12.43-42.45-5.97-32.97 21.89 6.57-9.43 21.36-10.62 30.92-19.29-11.57 66.54-108.57 34.95-157.51 55.32.8-8.45 16.86-20.15 23.26-23.6-35.2-14.45-81.47-38.14-90.12-73.58 80.09 51.41 248.66 32.25 268.01-37.85"
      transform="matrix(.13333 0 0 -.13333 74.368 361.64)"
    />
    <path
      style={{
        fill: '#b40b0b',
        fillOpacity: 1,
        fillRule: 'evenodd',
        stroke: 'none',
      }}
      d="M1300.2 1328.66c-143.42 38.73-106.19-113.76 9.6-118.84-5.96-5.03-12.57-9.84-19.93-14.38-15.29-9.44-30.19-16.69-44.82-21.72-121.41 75.16-74.82 237.68 55.15 154.94"
      transform="matrix(.13333 0 0 -.13333 74.368 361.64)"
    />
    <path
      style={{
        fill: '#b40b0b',
        fillOpacity: 1,
        fillRule: 'evenodd',
        stroke: 'none',
      }}
      d="M1051.28 1263.66c26.28 51.23 78.37 86.86 174.4 91.92-66.95 15.19-133.13 2.07-176.88-23.09-4.59-24.66-4.18-47.78 2.48-68.83"
      transform="matrix(.13333 0 0 -.13333 74.368 361.64)"
    />
    <path
      style={{
        fill: '#012b5d',
        fillOpacity: 1,
        fillRule: 'evenodd',
        stroke: 'none',
      }}
      d="M1087.69 1210.25c4.67 44.17 33.45 100.75 94.61 132.18-71.45-12.1-110.96-48.79-125.98-91.82 6.82-14.69 17.11-28.21 31.37-40.36"
      transform="matrix(.13333 0 0 -.13333 74.368 361.64)"
    />
    <path
      style={{
        fill: '#b40b0b',
        fillOpacity: 1,
        fillRule: 'evenodd',
        stroke: 'none',
      }}
      d="M1149.75 1173.5c-10.22 42.21-1.27 115.11 22.61 156.35-38-20.47-72.66-82.15-73.52-128.36 17.14-12.66 34.04-21.97 50.91-27.99"
      transform="matrix(.13333 0 0 -.13333 74.368 361.64)"
    />
    <path
      style={{
        fill: '#012b5d',
        fillOpacity: 1,
        fillRule: 'evenodd',
        stroke: 'none',
      }}
      d="M1220.4 1167.31c-29.17 23.6-58.9 75.72-56 120.49-13.25-39.13-10.02-94.25 7.22-120.25 16.14-3.05 32.34-3.1 48.78-.24"
      transform="matrix(.13333 0 0 -.13333 74.368 361.64)"
    />
    <path
      style={{
        fill: '#b40b0b',
        fillOpacity: 1,
        fillRule: 'evenodd',
        stroke: 'none',
      }}
      d="M662.012 965.238v26.742h160.031v-26.671h-123.66v-.071h-36.371"
      transform="matrix(.13333 0 0 -.13333 74.368 361.64)"
    />
    <path
      style={{
        fill: '#012b5d',
        fillOpacity: 1,
        fillRule: 'evenodd',
        stroke: 'none',
      }}
      d="M662.012 835.582v93.668h108.504v-25.469h-72.133V864.98h125.18v-29.398H662.012"
      transform="matrix(.13333 0 0 -.13333 74.368 361.64)"
    />
    <path
      style={{
        fill: '#012b5d',
        fillOpacity: 1,
        fillRule: 'nonzero',
        stroke: 'none',
      }}
      d="M930.805 898.02h68.601l-33.793 63.648zm-70.012-62.438 88.551 156.395h35.109l89.717-156.395h-40.87l-18.79 34.25h-99.213l-18.234-34.25h-36.27M1265.88 964.094h-113.67V864.98h113.67v34.856h-59.41v26.672h94.87v-53.949c0-14.297-2.68-24.047-8.03-29.2-5.31-5.203-15.61-7.777-30.82-7.777h-107.9c-15.21 0-25.46 2.574-30.76 7.777-5.31 5.153-7.99 14.903-7.99 29.2V955c0 14.195 2.68 23.895 7.99 29.148 5.3 5.204 15.55 7.829 30.76 7.829h107.9c15.01 0 25.26-2.579 30.72-7.676 5.4-5.153 8.13-14.399 8.13-27.785v-3.586l-35.46-6.414v17.578M1378.33 835.582v156.395h36.37V864.98H1532v-29.398h-153.67"
      transform="matrix(.13333 0 0 -.13333 74.368 361.64)"
    />
    <path
      style={{
        fill: '#b40b0b',
        fillOpacity: 1,
        fillRule: 'evenodd',
        stroke: 'none',
      }}
      d="M1585.39 965.238v26.742h160.04v-26.671h-123.66v-.071h-36.38"
      transform="matrix(.13333 0 0 -.13333 74.368 361.64)"
    />
    <path
      style={{
        fill: '#012b5d',
        fillOpacity: 1,
        fillRule: 'evenodd',
        stroke: 'none',
      }}
      d="M1585.39 835.582v93.668h108.51v-25.469h-72.13V864.98h125.18v-29.398h-161.56"
      transform="matrix(.13333 0 0 -.13333 74.368 361.64)"
    />
    <path
      style={{
        fill: '#b40b0b',
        fillOpacity: 1,
        fillRule: 'nonzero',
        stroke: 'none',
      }}
      d="M890.758 736v49.625h50.203v-8.656h-38.66v-13.078h20.867v-9.039h-20.867V736Zm64.515 0v49.625h11.543V736Zm36.918 0v49.625h50.779v-8.465h-39.24v-11.058h22.89v-8.461h-22.89v-12.313h39.72V736Zm72.599 0v49.625h11.54v-40.297h37.22V736Zm77.24 9.328h14.94c7.95 0 13.59 1.235 16.91 3.719 3.32 2.469 4.98 6.558 4.98 12.262 0 5.082-1.38 8.929-4.13 11.543-2.76 2.613-6.83 3.925-12.21 3.925h-20.49zM1130.48 736v49.625h31.94c9.12 0 16.12-2.129 21.01-6.379 4.91-4.266 7.36-10.308 7.36-18.129 0-4.633-.96-8.801-2.87-12.515-1.91-3.707-4.6-6.625-8.08-8.754-2.16-1.348-4.84-2.325-8.04-2.934-3.23-.609-7.33-.914-12.35-.914zm141.8 40.777V736h-11.54v40.777h-22.79v8.848h57.22v-8.848zM1309.31 736v49.625h50.78v-8.465h-39.24v-11.058h22.89v-8.461h-22.89v-12.313h39.72V736Zm114.24 40.777h-31.16v-31.449h31.16v10.676l11.54-2.402v-5.872c0-4.535-.85-7.625-2.57-9.261-1.7-1.653-4.93-2.469-9.73-2.469h-29.65c-4.83 0-8.08.816-9.76 2.469-1.68 1.636-2.54 4.726-2.54 9.261v26.176c0 4.492.86 7.582 2.54 9.235 1.68 1.652 4.93 2.484 9.76 2.484h29.65c4.8 0 8.03-.832 9.73-2.484 1.72-1.653 2.57-4.743 2.57-9.235v-4.711l-11.54-1.84zM1458.92 736v49.625h11.54v-19.234h36.07v19.234h11.64V736h-11.64v21.352h-36.07V736h-11.54"
      transform="matrix(.13333 0 0 -.13333 74.368 361.64)"
    />
  </svg>
);
