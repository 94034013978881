import {useEffect} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {useAuth, useLoggedInUserData} from './';

const protectedRoutes = ['/admin'];

export const useAuthRoute = () => {
  const {user: fbUser, loading} = useAuth();
  const {user} = useLoggedInUserData();
  const navigate = useNavigate();
  const {pathname: route} = useLocation();

  useEffect(() => {
    switch (true) {
      case loading: {
        return;
      }
      case !fbUser && route !== '/login': {
        navigate('/login', {replace: true});
        return;
      }
      case fbUser && route === '/login': {
        navigate('/', {replace: true});
        return;
      }
      // TODO: reenable once we have a way to fetch all users again
      // case user &&
      //   user.isSuperAdmin === false &&
      //   protectedRoutes.includes(route): {
      //   navigate('/', {replace: true});
      //   return;
      // }
    }
  }, [fbUser, loading]);

  return {loading};
};
