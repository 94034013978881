import {useState, useEffect} from 'react';
import {fetchTrailer} from '../api';
import {useAuth} from '.';

export const useTrailerData = trailerId => {
  const {authToken} = useAuth();
  const [loading, setLoading] = useState(false);
  const [trailer, setTrailer] = useState(undefined);
  const [error, setError] = useState(undefined);

  useEffect(() => {
    if (!authToken) return;
    if (!trailerId) return;

    setLoading(true);
    fetchTrailer({authToken, trailerId})
      .then(response => {
        setTrailer(response?.data);
        setError(undefined);
      })
      .catch(error => {
        setTrailer(undefined);
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [authToken, trailerId]);

  return {trailer, loading, error};
};
