import {createSlice} from '@reduxjs/toolkit';
import {format, addDays} from 'date-fns';
import {TimeZone} from '../enums';
import {Event, RawTicket, Ticket, User} from '../models';
import {getCurrentTimeZone} from '../utils';

const dateFormat = 'M/d/yyyy';
const today = format(new Date(), dateFormat);
const threeDaysAgo = format(addDays(new Date(), -3), dateFormat);

interface State {
  user?: User;
  pageName: string;
  users: User[];
  tickets: Ticket[];
  events: Event[];
  finalEvents: Event[];
  ticketsTableParams: {
    hiddenColumns: string[];
    startDate: string;
    endDate: string;
    haulerFilter?: string;
    trailerIdFilter?: number;
    siteFilter?: string;
    timeZone: TimeZone;
    combineRows: boolean;
    showAccuracyReport: boolean;
  };
  rawTickets: RawTicket[];
}

const initialState: State = {
  user: null,
  pageName: 'Dashboard',
  users: [],
  tickets: [],
  events: [],
  finalEvents: [],
  ticketsTableParams: {
    hiddenColumns: [],
    startDate: threeDaysAgo,
    endDate: today,
    haulerFilter: null,
    trailerIdFilter: null,
    siteFilter: null,
    timeZone: getCurrentTimeZone(),
    combineRows: true,
    showAccuracyReport: false,
  },
  rawTickets: [],
};

export const slice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setUser: (state, action) => {
      // state.user = {
      //   uid: action.payload.uid,
      //   email: action.payload.email,
      //   authToken: action.payload.authToken,
      // };
    },
    setUsers: (state, action) => {
      state.users = action.payload;
    },
    setTickets: (state, action) => {
      state.tickets = action.payload;
    },
    setEvents: (state, action) => {
      state.events = action.payload;
    },
    setFinalEvents: (state, action) => {
      state.finalEvents = action.payload;
    },
    setHiddenColumns: (state, action) => {
      state.ticketsTableParams.hiddenColumns = action.payload;
    },
    setStartDate: (state, action) => {
      state.ticketsTableParams.startDate = action.payload;
    },
    setEndDate: (state, action) => {
      state.ticketsTableParams.endDate = action.payload;
    },
    setHaulerFilter: (state, action) => {
      state.ticketsTableParams.haulerFilter = action.payload;
    },
    setTrailerIdFilter: (state, action) => {
      state.ticketsTableParams.trailerIdFilter = action.payload;
    },
    setSiteFilter: (state, action) => {
      state.ticketsTableParams.siteFilter = action.payload;
    },
    setTimeZone: (state, action) => {
      state.ticketsTableParams.timeZone = action.payload;
    },
    setCombineRows: (state, action) => {
      state.ticketsTableParams.combineRows = action.payload;
    },
    setShowAccuracyReport: (state, action) => {
      state.ticketsTableParams.showAccuracyReport = action.payload;
    },
    setPageName: (state, action) => {
      state.pageName = action.payload;
    },
    addRawTicket: (state, action) => {
      const ticketExists = Boolean(
        state.rawTickets.find(
          ticket => ticket.ticketId === action.payload.ticketId,
        ),
      );

      if (!ticketExists) {
        state.rawTickets = state.rawTickets.concat(action.payload);
      }
    },
  },
});

export const {
  setUser,
  setUsers,
  setTickets,
  setEvents,
  setFinalEvents,
  setHiddenColumns,
  setStartDate,
  setEndDate,
  setHaulerFilter,
  setTrailerIdFilter,
  setSiteFilter,
  setTimeZone,
  setCombineRows,
  setShowAccuracyReport,
  addRawTicket,
  setPageName,
} = slice.actions;

export default slice.reducer;
