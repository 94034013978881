import {useEffect, useState} from 'react';
import {useActions, useAppSelector, useAuth} from './';
import {fetchRawTicketData} from '../api';
import {eventTableColumns} from '../tableColumns';
import {Model, RawTicket} from '../models';

export const useRawTicketData = (ticketId: number) => {
  const {authToken} = useAuth();
  const [loading, setLoading] = useState(false);
  const [rawTicket, setRawTicket] = useState<RawTicket>();
  const {addRawTicket} = useActions();
  const {rawTickets} = useAppSelector(state => state.app);

  useEffect(() => {
    if (!authToken) return;

    const rawTicket = rawTickets.find(ticket => ticket.ticketId === ticketId);
    if (rawTicket) {
      setRawTicket(rawTicket);
    } else {
      setLoading(true);
      fetchRawTicketData({authToken, ticketId})
        .then(response => {
          const rawTicket = Model.createRawTicketFromApiObject(
            ticketId,
            response.data,
          );
          setRawTicket(rawTicket);
          addRawTicket(rawTicket);
        })
        .catch(error => {
          console.log('error fetching data\n', error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [authToken, ticketId]);

  return {rawTicket, columns: eventTableColumns, loading};
};
