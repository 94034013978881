import {Loader, Page, SitesTable} from '../components';
import {siteTableColumns} from '../tableColumns';
import {useActions, useSitesData} from '../hooks';
import { useEffect } from 'react';

export const SitesPage = () => {
  const {sites, loading} = useSitesData();
  const {setPageName} = useActions();

  useEffect(() => {
    setPageName('Sites');
  }, [setPageName]);
  
  return (
    <Page>
      <div style={{padding: '20px', display: 'flex', flex: 1, width: '100vw'}}>
        {loading ? (
          <Loader />
        ) : (
          <SitesTable sites={sites} columns={siteTableColumns} />
        )}
      </div>
    </Page>
  );
};
