import {Loader as LoaderSem} from 'semantic-ui-react';

export const Loader = () => (
  <div
    style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
      flex: 1
    }}>
    <LoaderSem active inline />
  </div>
);
