import {useState, useEffect} from 'react';
import {Button} from 'semantic-ui-react';
import {saveAs} from 'file-saver';
import {parse} from 'json2csv';

interface ExportButtonProps {
  className;
  json: any; // any js object
  columns: any;
  filename: string;
}

export const ExportButton = ({
  className,
  json,
  columns,
  filename,
}: ExportButtonProps) => {
  const [csv, setCsv] = useState('');

  const fields = columns.map(column => ({
    label: column.title,
    value: column.key,
  }));

  useEffect(() => {
    const csv = parse(json, {fields});
    setCsv(csv);
  }, [json]);

  return (
    <Button
      className={className}
      onClick={() => {
        const blob = new Blob([csv], {
          type: 'application/json',
        });
        saveAs(blob, `${filename}.csv`);
      }}>
      Export
    </Button>
  );
};
