import {
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHeaderCell,
  TableRow,
} from 'semantic-ui-react';
import {isNil, filter} from 'ramda';
import {TimeZone} from '../enums';
import {eventTableColumns as columns} from '../tableColumns';
import {Event} from '../models';

interface TicketTableProps {
  events: Event[];
  timeZone: TimeZone;
}

export const TicketTable = ({events, timeZone}: TicketTableProps) => {
  const spannableColumnKeys = filter(column => column.span, columns)
    .map(column => column.key);

  return (
    // @ts-ignore
    <Table celled structured unstackable>
      <TableHeader>
        <TableRow>
          {columns.map(column => (
            <TableHeaderCell key={column.key}>{column.title}</TableHeaderCell>
          ))}
        </TableRow>
      </TableHeader>
      <TableBody>
        {events.map((event, eventIndex) => (
          <TableRow key={event.id}>
            {columns.map((column, index) => {
              const {key, display} = column;
              const rowIsSpannable = spannableColumnKeys.includes(key);
              if (rowIsSpannable && eventIndex !== 0) return;

              const rowSpan = rowIsSpannable ? events.length : 1;
              const value = event[key];
              const displayValue =
                !isNil(value) && !isNil(display)
                  ? display(value, {timeZone})
                  : value || '';

              return (
                <TableCell rowSpan={rowSpan} key={`${key}-${index}`}>
                  {displayValue}
                </TableCell>
              );
            })}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};
