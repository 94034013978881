import {useState, useEffect} from 'react';
import {fetchTicket} from '../api';
import {Event, Model, Ticket} from '../models';
import {useAppSelector, useAuth} from './';

export const useTicketData = ticketId => {
  const {authToken} = useAuth();
  const [loading, setLoading] = useState(false);
  const [ticket, setTicket] = useState<Ticket>();
  const [events, setEvents] = useState<Event[]>([]);
  const {tickets} = useAppSelector(state => state.app);

  const setState = ticket => {
    const events = Model.convertTicketToEvents(ticket);
    setTicket(ticket);
    setEvents(events);
  };

  useEffect(() => {
    if (!authToken) return;

    const ticket = tickets.find(ticket => ticket.id === ticketId);
    if (ticket) {
      setState(ticket);
    } else {
      setLoading(true);
      fetchTicket({authToken, ticketId})
        .then(response => {
          const ticket = Model.createTicketFromApiObject(response.data);
          setState(ticket);
        })
        .catch(error => {
          console.log(error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [authToken, ticketId]);

  return {ticket, events, loading};
};
