import {useEffect, useState} from 'react';
import {useAppSelector} from './';

export const useTimeZoneMessage = () => {
  const {timeZone} = useAppSelector(state => state.app.ticketsTableParams);
  const [timeZoneMessage, setTimeZoneMessage] = useState('');

  useEffect(() => {
    const timeZoneMessage = `All times shown in ${timeZone}`;
    setTimeZoneMessage(timeZoneMessage);
  }, [timeZone]);

  return {timeZoneMessage};
};
