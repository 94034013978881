import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import {getAuth} from 'firebase/auth';
import firebase from 'firebase/compat/app';
import {LogoSvg} from '../svgs';

export const LoginPage = () => {
  const uiConfig = {
    autoUpgradeAnonymousUsers: false,
    signInSuccessUrl: '/',
    signInOptions: [
      // {
      //   provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      //   disableSignUp: {
      //     status: true,
      //   },
      // },
      {
        provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
        disableSignUp: {
          status: true,
        },
      },
    ],
  };

  return (
    <div
      style={{
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
      }}>
      <div style={{width: '200px', marginBottom: '20px'}}>
        <LogoSvg />
      </div>
      <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={getAuth()} />
    </div>
  );
};
