import {css} from '@emotion/css';
// import {useNavigate} from 'react-router-dom';
// import {Button} from 'semantic-ui-react';
import {UsersTable, Page} from '../components';

const styles = {
  grid: css`
    display: grid;
    padding: 20px;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    gap: 20px;
    @media (max-width: 719px) {
      grid-template-areas: 't';
    }
    @media (min-width: 720px) {
      grid-template-areas: 't';
    }
  `,
  gridAreaTable: css({
    gridArea: 't',
    overflowX: 'scroll',
  }),
};

export const AdminPage = () => {
  // const navigate = useNavigate();

  // const handleClickButton = () => {
  //   navigate('/user/new');
  // };

  return (
    <Page>
      <div className={styles.grid}>
        {/* <div className={styles.optionsRow}>
          <Button onClick={handleClickButton}>Add User</Button>
        </div> */}
        <div className={styles.gridAreaTable}>
          <UsersTable />
        </div>
      </div>
    </Page>
  );
};
