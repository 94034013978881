import {Loader, Page, TrailersTable} from '../components';
import {trailerTableColumns} from '../tableColumns';
import {useActions, useTrailersData} from '../hooks';
import { useEffect } from 'react';

export const TrailersPage = () => {
  const {trailers, loading} = useTrailersData();
  const {setPageName} = useActions();

  useEffect(() => {
    setPageName('Trailers');
  }, [setPageName]);
  

  return (
    <Page>
      <div style={{padding: '20px', display: 'flex', flex: 1, width: '100vw'}}>
        {loading ? (
          <Loader />
        ) : (
          <TrailersTable trailers={trailers} columns={trailerTableColumns} />
        )}
      </div>
    </Page>
  );
};
