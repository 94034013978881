import {
  Pickup,
  Disposal,
  Event,
  RawTicket,
  Site,
  Ticket,
  Trailer,
  User,
} from './';
import {isEmpty} from 'ramda';

export class Model {
  static createUserFromApiObject = (object): User => ({
    id: object.userId,
    firebaseUid: object.firebaseUid,
    email: object.email,
    name: object.name,
    companyId: object.companyId, // TODO: remove
    isSuperAdmin: object.isSuperAdmin,
    userType: object.userType,
  });

  static createPickupFromApiObject = (object): Pickup => ({
    id: object.pickupID,
    site: object.pickupSite?.assetName,
    operator: object.pickupSite?.company?.companyName,
    latitude: object.lat,
    longitude: object.long,
    startTime: object.startTime,
    endTime: object.endTime,
    siteArrivalTime: object.siteArrivalTime,
    siteDepartureTime: object.siteDepartureTime,
    duration: object.pickupDuration,
    startVolume: object.startBarrels,
    endVolume: object.endBarrels,
    // should be calculated on the backend, but due to faulty data we are calculating it here
    netVolume: object.endBarrels - object.startBarrels,
  });

  static createDisposalFromApiObject = (object): Disposal => ({
    id: object.disposalID,
    site: object.disposalSite?.assetName,
    operator: object.disposalSite?.company?.companyName,
    latitude: object.lat,
    longitude: object.long,
    startTime: object.startTime,
    endTime: object.endTime,
    siteArrivalTime: object.siteArrivalTime,
    siteDepartureTime: object.siteDepartureTime,
    duration: object.disposalDuration,
    startVolume: object.startBarrels,
    endVolume: object.endBarrels,
    netVolume: object.barrelChange,
  });

  static createTicketFromApiObject = (object): Ticket => ({
    id: object.ticketID,
    hauler: object.trailer?.company?.companyName,
    trailerId: object.trailer?.trailerIdentifier,
    deviceSerialNumber: object.trailer?.device?.deviceName,
    startTime: object.cycleStartTime,
    endTime: object.cycleEndTime,
    duration: object.cycleDuration,
    pickups: object.pickups.map(pickup =>
      Model.createPickupFromApiObject(pickup),
    ),
    disposals: object.disposals.map(disposal =>
      Model.createDisposalFromApiObject(disposal),
    ),
  });

  static createRawTicketFromApiObject = (ticketId, object): RawTicket => ({
    ticketId,
    deviceId: object.rawData[0]?.deviceid,
    moments: object.rawData.map(item => ({
      time: item._time,
      barrels: item.barrels,
      lat: item.lat,
      lon: item.lon,
      spd: item.spd,
      batt: item.batt,
    })),
  });

  static createTrailerFromApiObject = (object): Trailer => ({
    id: object.trailerID,
    haulerName: object.company?.companyName,
    eagleSerialNumber: object.device?.deviceName,
    vin: object.vin,
    online: object.online,
    trailerIdentifier: object.trailerIdentifier,
    installDate: object.installDate,
    sensorSerialNumber: object.sensorSerial,
    sensorCode: object.sensorPairCode,
    particleDeviceId: object.device?.particleID,
  });

  static createSiteFromApiObject = (object): Site => ({
    id: object.siteID,
    name: object.assetName,
    type: object.location_classification,
    ownerName: object.company?.companyName,
    latitude: object.lat,
    longitude: object.long,
  });

  static convertTicketToEvents = (ticket: Ticket): Event[] => {
    // api sometimes returns tickets with no events
    const pickupEvents = (() => {
      if (isEmpty(ticket.pickups)) {
        const pickupEvent = {
          ticketId: ticket.id,
          hauler: ticket.hauler,
          trailerId: ticket.trailerId,
          deviceSerialNumber: ticket.deviceSerialNumber,
          eventId: -1,
          type: 'Pickup',
        };
        return [pickupEvent];
      } else {
        return ticket.pickups.map(pickup => {
          const pickupEvent = {
            ticketId: ticket.id,
            hauler: ticket.hauler,
            trailerId: ticket.trailerId,
            deviceSerialNumber: ticket.deviceSerialNumber,
            eventId: pickup.id,
            type: 'Pickup',
            site: pickup.site,
            operator: pickup.operator,
            latitude: pickup.latitude,
            longitude: pickup.longitude,
            startTime: pickup.startTime,
            endTime: pickup.endTime,
            siteArrivalTime: pickup.siteArrivalTime,
            siteDepartureTime: pickup.siteDepartureTime,
            duration: pickup.duration,
            startVolume: pickup.startVolume,
            endVolume: pickup.endVolume,
            netVolume: pickup.netVolume,
          };
          return pickupEvent;
        });
      }
    })();

    const disposalEvents = (() => {
      if (isEmpty(ticket.disposals)) {
        const pickupEvent = {
          ticketId: ticket.id,
          hauler: ticket.hauler,
          trailerId: ticket.trailerId,
          deviceSerialNumber: ticket.deviceSerialNumber,
          eventId: -1,
          type: 'Disposal',
        };
        return [pickupEvent];
      } else {
        return ticket.disposals.map(disposal => {
          const pickupEvent = {
            ticketId: ticket.id,
            hauler: ticket.hauler,
            trailerId: ticket.trailerId,
            deviceSerialNumber: ticket.deviceSerialNumber,
            eventId: disposal.id,
            type: 'Disposal',
            site: disposal.site,
            operator: disposal.operator,
            latitude: disposal.latitude,
            longitude: disposal.longitude,
            startTime: disposal.startTime,
            endTime: disposal.endTime,
            siteArrivalTime: disposal.siteArrivalTime,
            siteDepartureTime: disposal.siteDepartureTime,
            duration: disposal.duration,
            startVolume: disposal.startVolume,
            endVolume: disposal.endVolume,
            netVolume: disposal.netVolume,
          };
          return pickupEvent;
        });
      }
    })();

    const events = [...pickupEvents, ...disposalEvents].map(event => ({
      id: `${event.ticketId}-${event.eventId}`,
      ...event,
    }));

    return events;
  };
}
