import { getValue } from '@testing-library/user-event/dist/utils';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Plugin,
  BarElement,
  LineController,
} from 'chart.js';
import {format} from 'date-fns';
import {useEffect, useRef, useState} from 'react';
import {Bar, Chart, Line} from 'react-chartjs-2';
import { Checkbox } from 'semantic-ui-react';
import {Loader} from '../components';
import { useLoggedInUserData } from '../hooks';
import {RawTicket, Event} from '../models';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  LineController
);

interface TicketChartProps {
  rawTicket: RawTicket;
  loading: boolean;
  events: Event[];
}

export const TicketChart = ({rawTicket, loading, events}: TicketChartProps) => {
  const [labels, setLabels] = useState([]);
  const [speed, setSpeed] = useState([]);
  const [volume, setVolume] = useState([]);
  const [battery, setBattery] = useState([]);
  const [timestamps, setTimestamps] = useState([]);
  const [showTimestamps, setShowTimestamps] = useState(true);

  const chartRef = useRef();

  const {user} = useLoggedInUserData();

  const findTime = (sortedData, timeType, timeTypeDisplay, data, labels) => {
    return {type: data.type, timeType: timeTypeDisplay, time: sortedData.findIndex(f => f.time === `${data[timeType]}+00:00`)};
  }

  const updateTimestampLines = (e, { checked }) => setShowTimestamps(checked);

  useEffect(() => {
    if (!rawTicket) return;

    const sortedData = [...rawTicket.moments].sort((a, b) =>
      a.time > b.time ? 1 : -1,
    );
    const labels = sortedData.map(i => format(new Date(i.time), 'HH:mm:ss'));
    const volume = sortedData.map(i => i.barrels);
    const speed = sortedData.map(i => i.spd * 2.23694);

    const importantTimestamps = [];

    events.forEach(e => {
      importantTimestamps.push(findTime(sortedData, 'startTime', 'Start', e, labels));
      importantTimestamps.push(findTime(sortedData, 'endTime', 'End', e, labels));
      importantTimestamps.push(findTime(sortedData, 'siteArrivalTime', 'Site Arrival', e, labels));
      importantTimestamps.push(findTime(sortedData, 'siteDepartureTime', 'Site Departure', e, labels));
    });

    setLabels(labels);
    setTimestamps(importantTimestamps);
    setVolume(volume);
    setSpeed(speed);
    if (user?.isSuperAdmin) {
      const batt = sortedData.map(i => i.batt);
      setBattery(batt);  
    }
  }, [rawTicket]);
  
  const chartData = {
    labels: labels,
    datasets: [
      {
        label: 'Barrels',
        data: volume,
        borderColor: 'rgb(255, 99, 132)',
        backgroundColor: 'rgba(255, 99, 132, 0.7)',
      },
      {
        label: 'Speed (mph)',
        data: speed,
        borderColor: 'rgb(53, 162, 235)',
        backgroundColor: 'rgba(53, 162, 235, 0.7)',
      },
    ],
  };

  if (user?.isSuperAdmin) {
    chartData.datasets.push(
      {
        label: 'Battery (%)',
        data: battery,
        borderColor: 'rgb(53, 235, 77)',
        backgroundColor: 'rgba(53, 235, 77, 0.7)',
      })
  }

    const buildPlugin = () => {
      if (showTimestamps) {
        sessionStorage.setItem('timestamps', JSON.stringify(timestamps));
      } else {
        sessionStorage.setItem('timestamps', JSON.stringify([]));
      }
      
      return [{
        id: "tooltipLine",

        afterDraw: (chart) => {
          const timestamps = JSON.parse(sessionStorage.getItem('timestamps'));
          timestamps.forEach(point => {
            if (point.time < 0) return;
            const meta = chart.getDatasetMeta(0); // first dataset is used to discover X coordinate of a point
            const data = meta.data;
            const lineLeftOffset = data[point.time]?.x || 0;
            const scale = chart.scales['y'];
      
            const context = chart.ctx;
            // render vertical line
            context.beginPath();
            context.strokeStyle = '#ADD8E6';
            context.moveTo(lineLeftOffset, scale.top);
            context.lineTo(lineLeftOffset, scale.bottom);
            context.stroke();  

            // write label
            context.save();
            const top = point.timeType === 'Start' || point.timeType === 'End' ? true : false;
            context.translate(lineLeftOffset - 5, top ? scale.top : scale.bottom);
            context.rotate(-Math.PI / 2);
            context.textAlign = top ? 'right' : 'left';
            context.font = "bold 10px Lato,'Helvetica Neue',Arial,Helvetica,sans-serif";
            context.fillText(`${point.type} ${point.timeType}`, 0, 5);
            context.restore();
          })
        },
      }];
  
    }
  
  return loading ? (
    <Loader />
  ) : (
    <div>
      <Chart
        type="line"
        ref={chartRef}
        options={{
          elements: {
            point: {
              radius: 1,
              borderWidth: 1,
            },
          },
          responsive: true,
          plugins: {
            title: {
              display: true,
              text: 'Sensor Data',
            },
          },
          interaction: {
            intersect: false,
            mode: 'index',
          },
          scales: {
            x: {
              display: true,
            },
            y: {
              display: true,
            },
          },
        }}
        data={chartData}
        plugins={buildPlugin()}
      />
      <Checkbox
        label="Show site activity"
        onChange={updateTimestampLines}
        checked={showTimestamps}
      />
    </div>
  );
};
