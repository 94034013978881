import axios from 'axios';
import {isNil} from 'ramda';
import env from './env.json';

// @ts-ignore
const {eftApiUrl: apiUrl, eftApiKey: apiKey} = env;

const headers = authToken => ({
  ApiKey: apiKey,
  Authorization: `Bearer ${authToken}`,
});

export const fetchTickets = ({authToken, startDate, endDate}) => {
  const url = `${apiUrl}/PullTicketData`;
  const params = {
    ...(!isNil(startDate) && {PullDate: startDate}),
    ...(!isNil(endDate) && {EndDate: endDate}),
  };

  return axios.get(url, {
    params,
    headers: headers(authToken),
  });
};

export const fetchTicket = ({authToken, ticketId}) => {
  const url = `${apiUrl}/PullTicketData/ById`;
  return axios.get(url, {
    params: {ticketId},
    headers: headers(authToken),
  });
};

export const fetchRawTicketData = ({authToken, ticketId}) => {
  const url = `${apiUrl}/PullRawTicketData`;
  return axios.get(url, {
    params: {ticketId},
    headers: headers(authToken),
  });
};

// API has changed... we currently have no way to fetch all users that are available to an admin
// export const fetchUsers = ({authToken}) => {
//   const url = `${apiUrl}/User`;
//   return axios.get(url, {
//     headers: headers(authToken),
//   });
// };

// API has changed... we currently have no way to fetch a user by id
// export const fetchUser = ({authToken, userId}) => {
//   const url = `${apiUrl}/User`;
//   return axios.get(url, {
//     params: {userId},
//     headers: headers(authToken),
//   });
// };

export const fetchLoggedInUser = ({authToken}) => {
  const url = `${apiUrl}/User`;
  return axios.get(url, {
    headers: headers(authToken),
  });
};

export const fetchTrailers = ({authToken}) => {
  const url = `${apiUrl}/trailers`;
  return axios.get(url, {
    headers: headers(authToken),
  });
};

export const fetchTrailer = ({authToken, trailerId}) => {
  const url = `${apiUrl}/trailers/location/${trailerId}`;
  return axios.get(url, {
    headers: headers(authToken),
  });
};

export const fetchSites = ({authToken}) => {
  const url = `${apiUrl}/sites`;
  return axios.get(url, {
    headers: headers(authToken),
  });
};
